import { Component } from "react";
// import { isMobile } from "react-device-detect";
import { customerImgList } from "../../shared/CustomerPageConst";
import missionControlImg from "../../styles/img/Customers/MissionControl2.png";
import shopImg from "../../styles/img/Customers/Shop2.png";
import myHotsauceImg from "../../styles/img/Customers/MyHotsauce2.png";
import resellerPortalImg from "../../styles/img/Customers/ResellerPortal2.png";
import "../../styles/CustomersPage.css";

class CustomersPage extends Component {
  render() {
    const imgDatabase = {
      "myhotsauce": myHotsauceImg,
      "mission-control": missionControlImg,
      "reseller": resellerPortalImg,
      "shop": shopImg,
    }
    return (
      <>
        <div className="customers-background" id="customers">
          <div className="customers-content-web">
            {customerImgList.map((content, idx) => {
              return (
                <a className="customers-link"
                  rel="noreferrer"
                  target="_blank"
                  href={content.href}
                  key={idx}>
                  <img className="link-image"
                    src={imgDatabase[content.imgName]}
                    alt={content.imgName} />
                </a>
              )
            })}
          </div>
        </div>
        <div className="customers-background-mobile" id="customers">
          <div className="customers-content-mobile">
            {customerImgList.map((content, idx) => {
              return (
                <a className="customers-link"
                  rel="noreferrer"
                  target="_blank"
                  href={content.href}
                  key={idx}>
                  <img className="link-image"
                    src={imgDatabase[content.imgName]}
                    alt={content.imgName} />
                </a>
              )
            })}
          </div>
        </div>
      </>
    );
  }
}
export default CustomersPage;