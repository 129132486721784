import { Component } from "react";
import { hotsaucePOSList } from "../../shared/HotsaucePOSPageConst";
import { ProductsCard } from "../../components/Card/index";
import "../../styles/ProductsPage.css";
class HotsaucePOSPage extends Component {
  render() {
    return (
      <div className="product-background" id="services" style={{ minHeight: '100%' }}>
        <div className="product-list" style={{ flexDirection: 'column' }}>
          {hotsaucePOSList.map((content) => {
            return (
              <ProductsCard
                key={content.Id}
                productId={content.Id}
                productTitle={content.Title}
                productIntro={content.SolutionIntro}
                productFeaturesList={content.FeatureList}
                displayImage={true}
                faqEnabled={false}
                learnMoreEnabled={true}/>
            )
          })}
        </div>
      </div>
    );
  }
}

export default HotsaucePOSPage;