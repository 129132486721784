export const customerImgList = [
  {
    imgName: "myhotsauce",
    href: "https://myhotsauce.net/login_page.aspx"
  }, {
    imgName: "mission-control",
    href: "https://missioncontrol.hotsaucepos.com/"
  }, {
    imgName: "reseller",
    href: "https://reseller.hotsaucepos.com/login.aspx"
  }, {
    imgName: "shop",
    href: "https://store.hotsaucepos.com/"
  }
]