import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as Chilli } from "../../styles/img/Icons/chilli.svg";
import { ReactComponent as Fire } from "../../styles/img/Icons/fire.svg";
import "../../styles/PolicyCard.css";

class PolicyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  renderFeatures = (policyList) => {
    return (
      <div >
        {policyList.map((policy, idx) => {
          return (
            <div className="policy-content" key={idx}>
              {policy.iconEnabled && <Fire className="fire-icon" />}
              <span className={"policy-description fontsize-l policy-item-description-title"}>
                {policy.title}
              </span>
              <span className="policy-description" />
              <span className="policy-description fontsize-l">
                {policy.url ? (
                  <a className="webinar-link"
                      rel="noreferrer"
                      target="_blank"
                      href={policy.url}>
                      {policy.content}
                    </a>
                ): (
                  policy.content
                )}
              </span>
            </div>
          );
        })}
      </div>
    )
  }

  render() {
    const { 
      policyId, 
      policyTitle, 
      policyIntro, 
      policyItemList,
    } = this.props;
    return (
      <div id={policyId} className="policy-content">
        <div className="policy-title-section">
          {/* <div className="policy-title">
            <Chilli className="chilli-icon" />
            <p className="fontsize-l">
              {policyTitle}
            </p>
          </div> */}
          {policyIntro != null ? <p className="policy-intro fontsize-l">{policyIntro}</p> : null}
          {policyItemList != null ? this.renderFeatures(policyItemList) : null}
        </div>
      </div>
    )
  }
}
export default PolicyCard;