import React, { Component } from 'react'
import { policyList } from "../../shared/PrivacyPolicyPageConst";
import { PolicyCard } from "../../components/Card/index";
import { BackToTopButton } from "../../components/Button/index";
class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {

  }

  render() {
    return (
      <div className="product-background" id="features-and-functions" style={{ minHeight: '100%' }}>
        <div className="product-list" style={{ flexDirection: "column" }}>
          {policyList.map((content) => {
            return (
              <PolicyCard
                key={content.id}
                policyId={content.id}
                policyTitle={content.title}
                policyIntro={content.policyIntro}
                policyItemList={content.policyItemList}
                modal={"feature-and-function"}/>
            )
          })}
        </div>
        <BackToTopButton
          className="scroll-to-top-btn"
          icon={"arrow"} />
      </div>
    )
  }
}
export default PrivacyPolicyPage;
