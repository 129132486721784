import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { ReactComponent as Inventory } from "../../styles/img/Icons/icon_inventory.svg";
import { ReactComponent as Labor } from "../../styles/img/Icons/icon_labor.svg";
import { ReactComponent as Mission } from "../../styles/img/Icons/icon_mission.svg";
import { ReactComponent as Database } from "../../styles/img/Icons/icon_database.svg";
import { ReactComponent as GiftCard } from "../../styles/img/Icons/icon_giftcard.svg";
import { ReactComponent as Office } from "../../styles/img/Icons/icon_office.svg";
import laborImg from "../../styles/img/labor_scheduling.png";
import missionImg from "../../styles/img/mission_control.png";
import inventoryImg from "../../styles/img/inventory_manager.png";
import serviceImg from "../../styles/img/First Page/About Us/Service3.png";
import businessImg from "../../styles/img/First Page/About Us/BusinessReporting.png";
import supportImg from "../../styles/img/First Page/About Us/CustomerSopport.png";
import redundancyImg from "../../styles/img/First Page/About Us/DataRedundancy.png";
import backOfficeImg from "../../styles/img/First Page/About Us/BackOffice.png";
import "../../styles/DescriptionCard.css";

class DescriptionCard extends Component {
  constructor() {
    super()
    this.state = {
      overflowActive: false,
      ellipsis: true,
    }
  }
  imgDatabase = {
    laborImg: laborImg,
    missionImg: missionImg,
    inventoryImg: inventoryImg,
    serviceImg: serviceImg,
    businessImg: businessImg,
    supportImg: supportImg,
    redundancyImg: redundancyImg,
    backOfficeImg: backOfficeImg
  };
  iconDatabase = {
    inventory: Inventory,
    labor: Labor,
    mission: Mission,
    database: Database,
    giftcard: GiftCard,
    office: Office,
  };

  isEllipsisActive(e) {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  }

  componentDidMount() {
    this.setState({ overflowActive: this.isEllipsisActive(this.content) });
  }

  render() {
    const { ellipsis, overflowActive } = this.state;
    const imgSrc = this.imgDatabase[this.props.image || 'pos'];
    const IconTagName = this.iconDatabase[this.props.icon || 'inventory'];
    const title = this.props.title;
    const content = this.props.content;
    const numIndex = this.props.numIndex;
    const indexEven = numIndex % 2 === 0;

    return (
      <div className={`description-card ${indexEven ? "grid-even" : "grid-odd"}`}>
        <IconTagName className={`description-icon ${indexEven ? "grid-icon-even" : "grid-icon-odd"}`} />
        <div className={`description-title fontsize-xl
          ${indexEven ? "grid-title-even" : "grid-title-odd"}
          ${isMobile ? "grid-title-mobile" : null}`}>
          {title}
        </div>
        <div className={indexEven ? "grid-content-even" : "grid-content-odd"}>
          <div className={`description-content fontsize-m ${ellipsis ? "content-ellipsis" : ""}`}
            onClick={() => { this.setState({ ellipsis: overflowActive ? !ellipsis : null }) }}>
            <span className="content-text fontsize-m" ref={ref => (this.content = ref)}>
              {content}
            </span>
            {ellipsis && overflowActive ? <div className="read-more-btn fontsize-m">...</div> : null}
          </div>
          <div className="learn-more-navigation">
            <Link className="learn-more-link" smooth="true" to="/contact">
              <Button className="learn-more-btn">
                LEARN MORE
              </Button>
            </Link>
          </div>
        </div>
        <div className={`
          ${indexEven ? "grid-image-even" : "grid-image-odd"}
          ${isMobile ? "grid-image-mobile" : null}`}>
          <img className="description-img" src={imgSrc} alt="description-img" />
        </div>
      </div >
    )
  }
}
export default DescriptionCard;