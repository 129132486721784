export const faqsList = [{
    Id: "general",
    Title: "GENERAL",
    SolutionIntro: "",
    FeatureList: [{
      title: "How long is the Implementation Process?",
      content: "2-3 weeks / Speed of implementation is based on business and menu information given to Hotsauce and communication with merchant", 
    },{
      title: "Is the System touch screen or do you need a mouse and keyboard at every terminal?",
      content: "All Server POS Stations are touch screens. The Back Office Server computer will require a mouse and keyboard.", 
    },{
      title: "Why do we need to use the Hotsauce Router?",
      content: "PCI Compliance requires a specific router be used with our system. This also allows Hotsauce to ensure your system stays private and secure .", 
    },{
      title: "Do the printers use the same paper?",
      content: "No, Kitchen Printers (Impact Printer) will require 3” x 150’ 1- Ply White Bond Paper or 3” x90’ 2-Ply Carbonless Paper. This printer also requires ink cartridges. One is supplied with the printer. " + 
      "Receipt Printers (Thermal Printer) require 3-⅛” x 230’ Thermal Paper.", 
    },{
      title: "Where can we get paper for printers?",
      content: "You can either order from our online store at store.hotsaucepos.com or there are many distributors that can provide both printer paper and kitchen printer ribbons including your food supplier. (pospaper.com, Staples, Office Depot, thermalpaperdirect.com) Hotsauce does not supply free pos paper", 
    },{
      title: "Does HotSauce have training videos that the merchant can share with staff prior to hardware install?",
      content: "Yes, please see below -",
      links: [{
        title: "Full Service - English",
        url: "https://www.youtube.com/watch?v=ZPyatPJ0cek",
      },{
        title: "Full Service - Spanish",
        url: "https://youtu.be/OyBimljR2jc",
      },{
        title: "Quick Counter Service / Bar - English",
        url: "https://www.youtube.com/watch?v=Brg5rIwokSA",
      },{
        title: "Handheld A920",
        url: "https://youtu.be/gw6Pf_ffDL8",
      }],
    },{
      title: "Can the merchant request to have a non-english speaker for the implementation process and/or Support?",
      content: "Yes, we do have Spanish and Tagalog speakers available for the Implementation Department. For our 24/7 Support Department, we have Spanish, Tagalog, and Mandarin speakers available.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "Is their system set up for the training mode? Would card transactions actually go through in training mode?",
      content: "The system is NOT in training mode, as we only do this at the owner's request. Card transactions will not go through nor will the transactions save on the system.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "Is the Hotsauce back office setup on the actual POS system itself? If so, How do they print the reports from there?",
      content: "Hotsauce Back office is set on only the SERVER terminal." + 
      "They can have the reports emailed to them, or they can print on the receipt printer attached to said terminal.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "With the Pax S300 chip reader, would they insert the chip when opening a tab or just create the tab by using a name? (The training video mentioned if using the swipe)",
      content: "Technically both. When they click \"Start a Tab\" it will pop up with the options to enter a name, SWIPE a card, or start a tab with the chip reader. If they start the tab with the chip reader, they will still either need to enter the name or swipe the card on the terminal as the chip reader does not pull a name.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "Offline payments - is the system already setup if the internet goes down or do they need to adjust settings?",
      content: "If the internet goes down, the settings will have to be changed manually. This is because we have to ensure the merchant is aware that when they are processing offline, they will not know if they get any declined cards until it is connected to the internet once again.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "Can I use my current “back office computer”?",
      content: "Hotsauce doesn't install on any 3rd party hardware." + 
      "If you want a computer for reporting and menu edits in the back office, you would need to add the \"Elite Server\" to the contract.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "Why are Manager Cards needed?",
      content: "Manager cards are an easier way to clock in/access the FOH on the POS. They work the same way as the 4 digit pin, except you just have to swipe the card!",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "Is the Hotsauce System cloud based ?",
      content: "The Hotsauce System is NOT cloud based. All of the information is only held on the POS terminal.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "How many Modifiers can I have?",
      content: "Modifiers are unlimited. You can have as many as you would like.",
      links: [{
        title: "",
        url: "",
      }],
    },{
      title: "How many Buttons can fit on the screen at once?",
      content: "There is enough room for 21 buttons to be shown at once, but we can add more.",
      links: [{
        title: "",
        url: "",
      }],
    }]
  }, {
    Id: "online-ordering",
    Title: "ONLINE ORDERING",
    SolutionIntro: "",
    FeatureList: [{
      title: "Does Hotsauce have an Online ordering option?",
      content: "Yes. A contract needs to be submitted through Spice Link, once completed, we will wait for your POS menu to be updated and ready to be copied over to your webstore." + 
      " We will then copy over all the details and build your website menu basing it from the POS system and the updated physical menu that will be provided to us.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "Does Hotsauce Integrate with Third Party Delivery services such as UberEats, GrubHub, Etc?",
      content: "HotSauce is not currently integrated with any third party delivery service. We can set up payment option buttons labeled with whichever third party delivery services you work with, but the orders will have to be manually entered into the POS System.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "When will online ordering be implemented?",
      content: "The Online Ordering is usually implemented 2-3 days after the client is installed and live if the online ordering team received all the required info from the merchant or agent.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "Can we set up an Auto Accept for online orders?",
      content: "Yes. It is set up this way, we currently use Authorize.net as our payment gateway.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "Is there a time frame for accepting online orders once submitted?",
      content: "Yes. There can be a default wait time that will be sent out with the order confirmation that the customers will receive via email or their mobile device. It can also easily be accessed and controlled on the main POS terminal by merchant",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "Can Hotsauce set up a separate Monitor in the kitchen for all online orders only?",
      content: "If you have a Kitchen Display Logic Control System, we can easily route the orders through that monitor if you will set up a separate one than that of the POS orders.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "When an online order comes through, is it automatically printed on the kitchen printer or how is someone notified there is a new order?",
      content: "It automatically prints to the kitchen, which is why our online ordering requires credit card payment, but on the front of house there will be multiple areas that notify the servers and hostess that an online order was entered so they will be aware.",
      links: [{
        title: "",
        url: "",
      }]
    }],
  }, {
    Id: "giftcards-loyalty",
    Title: "GIFT CARD",
    SolutionIntro: "",
    FeatureList: [{
      title: "Does Hotsauce have a Gift Card program?",
      content: "Yes. A contract needs to be submitted through SpiceLink.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "Can you import the balance?",
      content: "Yes, we can import previous gift card balances for a one time fee. We can not import $0.00 balances. This is just about honoring cards that already have a remaining balance. A Spreadsheet of the FULL CARD NUMBER and REMAINING BALANCE are required." + 
      " There is a chance you may not be able to swipe the previous cards, depending on which track the data is stored for the magnetic strip reader. In this case, you would type the full number of the previous cards in order to use them.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "If we have another location, can our customer use the same gift card?",
      content: "Yes.",
      links: [{
        title: "",
        url: "",
      }]
    },],
  }, {
    Id: "mission-control",
    Title: "REPORTING",
    SolutionIntro: "",
    FeatureList: [{
      title: "Can I access my reports when I am not at the Restaurant?",
      content: "Add On Feature, Mission Control: Reporting allows for this.",
      links: [{
        title: "",
        url: "",
      }]
    },],
  }, {
    Id: "mobile-POS",
    Title: "PAX A920",
    SolutionIntro: "",
    FeatureList: [{
      title: "Can I have A920’s without chip readers?",
      content: "No, the A920 is a wireless digital credit card terminal being used for order and pay at the table device. The chip reader will always be active.",
      links: [{
        title: "",
        url: "",
      }]
    },{
      title: "How does A920 get signals?",
      content: "A920 gets signal from Wireless Access Point. More than 1 Wireless Access Point may be needed",
      links: [{
        title: "",
        url: "",
      }]
    }],
  }, 
  // {
  //   Id: "KDS",
  //   Title: "KITCHEN DISPLAY SYSTEM",
  //   SolutionIntro: "",
  //   FeatureList: [],
  // }, {
  //   Id: "kiosk",
  //   Title: "KIOSK",
  //   SolutionIntro: "",
  //   FeatureList: [],
  // }, {
  //   Id: "software-integrations",
  //   Title: "SOFTWARE INTEGRATIONS",
  //   SolutionIntro: "",
  //   FeatureList: [],
  // }
]