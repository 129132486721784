import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Button } from 'react-bootstrap';
// import { isMobile } from "react-device-detect";
import efficientOrderImg from "../../styles/img/First Page/HotsaucePOS/Efficient Ordering.png";
import flexibleDiscountImg from "../../styles/img/First Page/HotsaucePOS/Flexible Discount.png";
import tableTrackingImg from "../../styles/img/First Page/HotsaucePOS/Table Tracking.png";
import missionControlImg from "../../styles/img/First Page/Solution/Mission Control.png";
import mobilePOSImg from "../../styles/img/First Page/Solution/Mobile POS.png";
import onlineOrderImg from "../../styles/img/First Page/Solution/Online Ordering.png";
import tablesideSolutionImg from "../../styles/img/First Page/Solution/Tablet.png";
import KDSImg from "../../styles/img/First Page/Solution/KDS.png";
import giftCard  from "../../styles/img/Icons/gift_card.svg";
import softwareIntegrations from "../../styles/img/Icons/software_integrations.svg";
import "../../styles/DeviceCard.css";

class DeviceCard extends Component {
  imgDatabase = {
    efficientOrder: efficientOrderImg,
    flexibleDiscount: flexibleDiscountImg,
    tableTracking: tableTrackingImg,
    missionControl: missionControlImg,
    mobilePOS: mobilePOSImg,
    onlineOrder: onlineOrderImg,
    tablesideSolution: tablesideSolutionImg,
    KDS: KDSImg,
    giftCard: giftCard,
    softwareIntegrations: softwareIntegrations,
  };
  render() {
    const groupName = this.props.groupName;
    const anchorHash = this.props.anchorHash;
    const title = this.props.title;
    const imgSrc = this.imgDatabase[this.props.image || 'pos'];
    return (
      <>
        <div className="device-card">
          <Button className={`device-img-btn ${groupName === "hotsauce-pos"?"device-pos-btn":""}`}>
            <HashLink smooth="true" to={"/" + groupName + anchorHash}>
              <img className="device-img" src={imgSrc} alt="device-img" />
              <div className="device-overlay device-card-title fontsize-xl">
                {title}
              </div>
            </HashLink>
          </Button>
        </div>
        <div className="device-card-mobile">
          <Button className={`device-img-btn ${groupName === "hotsauce-pos"?"device-pos-btn":""}`}>
            <HashLink smooth="true" to={"/" + groupName + anchorHash}>
              <img className="device-img" src={imgSrc} alt="device-img" />
            </HashLink>
          </Button>
          <div className="device-card-title fontsize-m device-card-title-mobile">
            {title}
          </div>
        </div>
      </>
    )
  }
}
export default DeviceCard;