import { Component } from "react";
import { Link } from "react-router-dom";
import "../../styles/ProductTable.css";

class ProductTable extends Component {
    constructor(props) {
      super(props);
      this.state = {

      }
    }

    componentDidMount = () => {

    }

    render() {
      const { products } = this.props;
      return (
        <>
          <div className="product-table-web">
            <div className="product-table-section">
              <div className="product-table-title">
                {products.title}
              </div>
              {products.hardwares.map((hardware, index) => {
                return (
                  <div className={
                    hardware.descriptions.length > 0 ? "product-table-content" : "product-table-content no-description"}>
                    <div className="product-table-subtitle pdf-fontsize-xl">
                      {hardware.title}
                    </div>
                    {hardware.descriptions.length > 0 && (
                      <div className="product-description-section">
                      {hardware.descriptions.map((description, id) => {
                        return (
                          <div className="product-description pdf-fontsize-l">
                            {description.link ? (
                              <a className="product-link" 
                                href={description.link} 
                                target="_blank"
                                rel="noopener noreferrer">
                                {description.content}
                              </a>) : (
                              description.content
                            )}
                          </div>
                        )
                      })}
                      </div>
                    )}
                    
                  </div>
                )
              })}
            </div>
          </div>
          <div className="product-table-mobile">
            <div className="product-table-section">
              <div className="product-table-title">
                {products.title}
              </div>
              {products.hardwares.map((hardware, index) => {
                return (
                  <div className={
                    hardware.descriptions.length > 0 ? "product-table-content" : "product-table-content no-description"}>
                    <div className="product-table-subtitle pdf-fontsize-xl">
                      {hardware.title}
                    </div>
                    {hardware.descriptions.length > 0 && (
                      <div className="product-description-section">
                      {hardware.descriptions.map((description, id) => {
                        return (
                          <div className="product-description pdf-fontsize-l">
                            {description.link ? (
                              <a className="product-link" 
                                href={description.link} 
                                target="_blank"
                                rel="noopener noreferrer">
                                {description.content}
                              </a>) : (
                              description.content
                            )}
                          </div>
                        )
                      })}
                      </div>
                    )}
                    
                  </div>
                )
              })}
            </div>
          </div>
        </>
      );
    }
}
export default ProductTable;