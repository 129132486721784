import React, { Component } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
// import { BsFillArrowUpCircleFill } from "react-icons/bs";

class BackToTopButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollToTopVisible: false,
    };
  }
  components = {
    arrow: FaArrowCircleUp,
    circleArrow: FaArrowCircleUp,
  }
  componentDidMount = () => {
    window.addEventListener('scroll', this.toggleVisible);
  }
  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500){
      this.setState({
        scrollToTopVisible: true,
      })
    } 
    else if (scrolled <= 500){
      this.setState({
        scrollToTopVisible: false,
      })
    }
  };
  handleScrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });
  }
  render() {
    const IconName = this.components[this.props.icon || 'arrow'];
    return (
      <IconName
      className={this.props.className}
      style={{display: this.state.scrollToTopVisible ? 'inline' : 'none'}} 
      onClick={this.handleScrollToTop}/>
    )
  }
}
export default BackToTopButton;