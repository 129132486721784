import { Component } from "react";
import { faqsList } from "../../shared/FaqsPageConst";
import { ProductsCard } from "../../components/Card/index";
import { BackToTopButton } from "../../components/Button/index";
import "../../styles/FaqsPage.css";
class FaqsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount = () => {
  }
  render() {
    return (
      <div className="product-background" id="faqs" style={{ minHeight: '100%' }}>
        <div className="product-list" style={{ flexDirection: "column" }}>
          {faqsList.map((content) => {
            return (
              <ProductsCard
                key={content.Id}
                productId={content.Id}
                productTitle={content.Title}
                productIntro={content.SolutionIntro}
                productFeaturesList={content.FeatureList}
                faqEnabled={false} 
                learnMoreEnabled={false}
                modal={"faq"}/>
            )
          })}
        </div>
        <BackToTopButton
          className="scroll-to-top-btn"
          icon={"arrow"} />
      </div>
    );
  }
}

export default FaqsPage;