import { Component } from "react";
import { companyInfo, copyrightInfo } from "../../shared/FooterConst";
// import { isMobile } from "react-device-detect";
import "../../styles/Footer.css";
class Footer extends Component {
  render() {
    return (
      <>
        <div className="footer-background">
          <hr className="dividing-line" />
          <div className="footer-content">
            <div className="footer-left">
              <h5 className="company-name fontsize-s">
                {companyInfo.Name}
              </h5>
              {companyInfo.Content.map((content, idx) => {
                return (<p className="company-info fontsize-xs" key={idx}>{content}</p>)
              })}
            </div>
            <div className="footer-right">
              {copyrightInfo.map((content, idx) => {
                return (<span className="copyright fontsize-xs" key={idx}>{content}</span>)
              })}
            </div>
          </div>
        </div>
        <div className="footer-background-mobile">
          <hr className="dividing-line" />
          <div className= "footer-content-mobile">
            <div className="footer-left">
              <h5 className="company-name fontsize-s">
                {companyInfo.Name}
              </h5>
              {companyInfo.Content.map((content, idx) => {
                return (<p className="company-info fontsize-xs" key={idx}>{content}</p>)
              })}
            </div>
            <div className="footer-right">
              {copyrightInfo.map((content, idx) => {
                return (<span className="copyright fontsize-xs" key={idx}>{content}</span>)
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;