import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import rdProgramSpanish from "../../styles/img/RDProgram/RDProgram_spanish.png";
import rdProgramEnglish from "../../styles/img/RDProgram/RDProgram_english.png";
import "../../styles/RDProgramPage.css";
import { Button } from "react-bootstrap";
class RDProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "rd-program-english",
    };
  }
  componentDidMount = () => {
    if(window.location.pathname.includes("spanish")) {
      this.setState({
        language: "rd-program-spanish",
      })
    } else {
      this.setState({
        language: "rd-program-english",
      })
    }
  }

  render() {
    const imageDatabase = {
      "rd-program-spanish": rdProgramSpanish,
      "rd-program-english": rdProgramEnglish,
    }

    return (
      <div className="rdprogram-main">
        <div className="btn-group">
          <Link to="/rdprogram/english">
            <Button className="btn-language">English</Button>
          </Link>
          <Link to="/rdprogram/spanish">
            <Button className="btn-language">Español</Button>
          </Link>
        </div>

        <img
          src={imageDatabase[this.state.language]}
          alt="Background"
          className="program-img"
        />
    </div>
    );
  }
}

export default withRouter(RDProgramPage);