// import logo from './logo.svg';
import './App.css';
import React, { Component } from "react";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
import { Router } from "./components";
// import createStateStore from "./redux/store";
// import { HomePage } from './pages';

class App extends Component {
  render() {
    // const { store, persistor } = createStateStore();
    return (
      // <Provider store={store}>
      //   <PersistGate loading={null} persistor={persistor}>
          <Router />
      //   </PersistGate>
      // </Provider>
    );
  }
}

export default App;
