import React, { Component } from 'react';
import { IoWineSharp, IoDocumentText } from "react-icons/io5";
import { IoMdBeer, IoMdBusiness } from "react-icons/io";
import { FaChalkboardTeacher, FaChartPie, FaPizzaSlice } from "react-icons/fa";
import { MdRestaurant, MdDataUsage, MdMultilineChart, MdDirectionsCar } from "react-icons/md";
import "../../styles/ClientCard.css";

class ClientCard extends Component {
  components = {
    wine: IoWineSharp,
    beer: IoMdBeer,
    dinner: MdRestaurant,
    chalk: FaChalkboardTeacher,
    chartpie: FaChartPie,
    data: MdDataUsage,
    multiline: MdMultilineChart,
    business: IoMdBusiness,
    document: IoDocumentText,
    car: MdDirectionsCar,
    pizza: FaPizzaSlice,
  };
  
  render() {
    const TagName = this.components[this.props.icon || 'wine'];
    const title = this.props.title;
    const content = this.props.content;
    return (
      <div className="client-card">
        <div className="client-circle">
          <TagName className="client-icon iconsize-l" />
        </div>
        <div className="client-title fontsize-xl">
          {title}
        </div>
        <div className="client-content fontsize-m">
          {content}
        </div>
      </div>
    )
  }
}

export default ClientCard;