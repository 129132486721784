import { Component } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
// import { isMobile } from "react-device-detect";
import { Formik } from "formik";
import {
  ContactSchema,
  StatesList,
  requestFormMobileList,
  requestFormWebList,
  notice,
  requestFormHint,
  formikInitialValues,
  requestModalInfo,
  contactInfo,
  careerInfo,
} from "../../shared/ContactPageConst";
import "../../styles/ContactPage.css";

class ContactPage extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      modalMessage: requestModalInfo.default,
    };
  }
  GoogleSheetAPI =
    "https://script.google.com/macros/s/AKfycbywk9yfA0iHxIM8DQuFNaLZz9v1TgFEbJM_6YILkJVAYIAg3yxU90CfmIYY27KI8SC2/exec";
  onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    console.table(values);
    await fetch(this.GoogleSheetAPI, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "content-type": "application/json",
      },
      cache: "no-cache",
      mode: "no-cors",
      redirect: "follow",
    })
      .then((r) => {
        resetForm({});
        this.setState({
          modalOpen: true,
          modalMessage: requestModalInfo.success,
        });
        // alert("Request sent");
        // setStatus({ success: true });
      })
      .catch((err) => {
        // setStatus({ success: false });
        setSubmitting(false);
        this.setState({
          modalOpen: true,
          modalMessage: requestModalInfo.false,
        });
        // setErrors({ submit: err.message });
        alert(`Error when sending, ${err.message}`);
      });
  };

  renderRequestForm() {
    // const formList = !isMobile ? requestFormWebList : requestFormMobileList
    return (
      <Formik
        initialValues={formikInitialValues}
        validationSchema={ContactSchema}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => (
          <Form noValidate onSubmit={formikProps.handleSubmit}>
            <div className="support-title fontsize-l text-white">
              CONTACT US
            </div>
            {this.renderForm(formikProps)}
          </Form>
        )}
      </Formik>
    );
  }

  renderFormList = (requestformList, formikProps) => {
    return (
      <>
        <div className="request-form-list-web">
          {requestformList.map((rowItem) => {
            return (
              <Form.Group
                key={rowItem.fieldName}
                as={Form.Col}
                className="request-form-group"
              >
                <Form.Label className="request-form-title fontsize-m">
                  {rowItem.Title}
                  {rowItem.star ? (
                    <span className="star-mark fontsize-m">*</span>
                  ) : null}
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={rowItem.fieldName === "us_states" ? "select" : "input"}
                    required={rowItem.fieldName !== "us_states" && rowItem.star}
                    id={rowItem.fieldName}
                    value={formikProps.values[rowItem.fieldName] ?? ""}
                    onChange={formikProps.handleChange}
                    className="request-form-control fontsize-m"
                    isValid={
                      formikProps.touched[rowItem.fieldName] &&
                      !formikProps.errors[rowItem.fieldName]
                    }
                  >
                    {rowItem.fieldName === "us_states"
                      ? StatesList.map((state, index) => {
                          return <option key={index}>{state}</option>;
                        })
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formikProps.errors[rowItem.fieldName]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            );
          })}
        </div>
        <div className="request-form-list-mobile">
          {requestformList.map((rowItem) => {
            return (
              <Form.Group
                key={rowItem.fieldName}
                as={Form.Col}
                className="request-form-group"
              >
                <Form.Label className="request-form-title fontsize-m">
                  {rowItem.Title}
                  {rowItem.star ? (
                    <span className="star-mark fontsize-m">*</span>
                  ) : null}
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={rowItem.fieldName === "us_states" ? "select" : "input"}
                    required={rowItem.fieldName !== "us_states" && rowItem.star}
                    id={rowItem.fieldName}
                    value={formikProps.values[rowItem.fieldName] ?? ""}
                    onChange={formikProps.handleChange}
                    className="request-form-control fontsize-m"
                    isValid={
                      formikProps.touched[rowItem.fieldName] &&
                      !formikProps.errors[rowItem.fieldName]
                    }
                  >
                    {rowItem.fieldName === "us_states"
                      ? StatesList.map((state, index) => {
                          return <option key={index}>{state}</option>;
                        })
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formikProps.errors[rowItem.fieldName]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            );
          })}
        </div>
      </>
    );
  };

  renderAdditionalAndSubmit = (formikProps) => {
    return (
      <>
        <Form.Group className="request-form-additional">
          <Form.Label className="request-form-title fontsize-m">
            Additional Needs
          </Form.Label>
          <Form.Control
            id="addition_needs"
            as="textarea"
            value={formikProps.values["addition_needs"] ?? ""}
            onChange={formikProps.handleChange}
            className="additional-form-control fontsize-m"
          />
        </Form.Group>
        <div className="request-submit">
          <Button className="request-submit-btn fontsize-m" type="submit">
            Request Information
          </Button>
        </div>
        <Form.Group className="request-form-additional-mobile">
          <Form.Label className="request-form-title fontsize-m">
            Additional Needs
          </Form.Label>
          <Form.Control
            id="addition_needs"
            as="textarea"
            value={formikProps.values["addition_needs"] ?? ""}
            onChange={formikProps.handleChange}
            className="additional-form-control fontsize-m"
          />
        </Form.Group>
        <div className="request-submit-mobile">
          <Button className="request-submit-btn fontsize-m" type="submit">
            Request Information
          </Button>
        </div>
      </>
    );
  };

  renderForm = (formikProps) => {
    return (
      <>
        <div className="request-form-web">
          <div className="request-hint-text fontsize-s">
            {requestFormHint.map((content) => {
              return content;
            })}
          </div>
          <div className="left-section">
            {this.renderFormList(requestFormWebList.left, formikProps)}
          </div>
          <div className="right-section">
            {this.renderFormList(requestFormWebList.right, formikProps)}
            {this.renderAdditionalAndSubmit(formikProps)}
          </div>
        </div>
        <div className="request-form-mobile">
          <div className="request-hint-text fontsize-s">
            {requestFormHint.map((content) => {
              return content;
            })}
          </div>
          <div className="left-section">
            {this.renderFormList(requestFormMobileList.left, formikProps)}
          </div>
          <div className="right-section">
            {this.renderFormList(requestFormMobileList.right, formikProps)}
          </div>
          {this.renderAdditionalAndSubmit(formikProps)}
        </div>
      </>
    );
  };
  renderCareer() {
    return (
      <>
        <div className="support-title fontsize-l text-white">
          {careerInfo.Title}
        </div>
        <div className="support-section">
          <div className="career-content fontsize-m">{careerInfo.Content}</div>
        </div>
      </>
    );
  }

  renderContact() {
    return (
      <>
        <div className="support-title fontsize-l text-white">
          {contactInfo.Title}
        </div>
        <div className="contact-section-web">
          {contactInfo.Content.map((info) => {
            return (
              <div className={info.category === "COMPANY" ? "list-companies" : "list-others"}>
                {info.category === "COMPANY" ? (
                  info.content.map((company) => {
                    return (
                      <div className="contact-list" key={company.Title}>
                        <div className="contact-title fontsize-l text-yellow">
                          {company.Title}
                        </div>
                        <div className="contact-content fontsize-m">
                          {company.Content.map((text, idx) => {
                            return <span key={idx}>{text}</span>;
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  info.content.map((company) => {
                    return (
                      <div className="contact-list" key={company.Title}>
                        <div className="contact-title fontsize-l text-yellow">
                          {company.Title}
                        </div>
                        <div className="contact-content fontsize-m">
                          {company.Content.map((text, idx) => {
                            return <span key={idx}>{text}</span>;
                          })}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )
          })}
        </div>
        <div className="contact-section-mobile">
          {contactInfo.Content.map((info) => {
            return (
              <div className={info.category === "COMPANY" ? "list-companies" : "list-others"}>
                {info.category === "COMPANY" ? (
                  info.content.map((company) => {
                    return (
                      <div className="contact-list" key={company.Title}>
                        <div className="contact-title fontsize-l text-yellow">
                          {company.Title}
                        </div>
                        <div className="contact-content fontsize-m">
                          {company.Content.map((text, idx) => {
                            return <span key={idx}>{text}</span>;
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  info.content.map((company) => {
                    return (
                      <div className="contact-list" key={company.Title}>
                        <div className="contact-title fontsize-l text-yellow">
                          {company.Title}
                        </div>
                        <div className="contact-content fontsize-m">
                          {company.Content.map((text, idx) => {
                            return <span key={idx}>{text}</span>;
                          })}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )
          })}
        </div>
      </>
    );
  }
  renderModal = () => {
    const { modalOpen, modalMessage } = this.state;
    return (
      <div
        className="modal-background"
        onClick={() => {
          this.setState({ modalOpen: false });
        }}
      >
        <Modal
          className="modal-box"
          show={modalOpen}
          onHide={() => {
            this.setState({ modalOpen: false });
          }}
          onExited={() => {
            this.setState({ modalOpen: false });
          }}
        >
          <Modal.Body className="modal-body fontsize-m">
            {modalMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="fontsize-m modal-btn"
              variant="ok"
              onClick={() => {
                this.setState({ modalOpen: false });
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  render() {
    const { modalOpen } = this.state;
    return (
      <div className="support-background" id="support">
        <div className="support-content">
          <div className="notice-content">
            <div className="notice-text fontsize-s">
              <span className="notice-title">{notice.Title}</span>
              {notice.Content.map((content, idx) => {
                return <span key={idx}>{content}</span>;
              })}
            </div>
          </div>
          {/* <Button onClick={() => { this.setState({ modalOpen: true }) }}>test</Button> */}
          {this.renderRequestForm()}
          {this.renderCareer()}
          {this.renderContact()}
        </div>
        {modalOpen ? this.renderModal() : null}
      </div>
    );
  }
}
export default ContactPage;
