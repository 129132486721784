import { Component } from "react";
import { solutionsList } from "../../shared/SolutionsPageConst";
import { ProductsCard } from "../../components/Card/index";
import { BackToTopButton } from "../../components/Button/index";
import "../../styles/ProductsPage.css";
class SolutionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollToTopVisible: false,
    };
  }
  componentDidMount = () => {
    window.addEventListener('scroll', this.toggleVisible);
  }
  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500){
      this.setState({
        scrollToTopVisible: true,
      })
    } 
    else if (scrolled <= 500){
      this.setState({
        scrollToTopVisible: false,
      })
    }
  };
  handleScrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });
  }
  render() {
    return (
      <div className="product-background" id="solutions" style={{ minHeight: '100%' }}>
        <div className="product-list" style={{ flexDirection: "column" }}>
          {solutionsList.map((content) => {
            return (
              <ProductsCard
                key={content.Id}
                productId={content.Id}
                productTitle={content.Title}
                productIntro={content.SolutionIntro}
                productFeaturesList={content.FeatureList}
                faqEnabled={true} 
                learnMoreEnabled={true}
                displayImage={true}/>
            )
          })}
        </div>
        <BackToTopButton
          className="scroll-to-top-btn"
          icon={"arrow"} />
      </div>
    );
  }
}

export default SolutionsPage;