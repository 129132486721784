export const hotsaucePOSList = [{
  Id: "efficient-ordering",
  Title: "EFFICIENT ORDERING",
  SolutionIntro:
    "Easily manage the most complicated orders, whether you need to transfer tickets to other users, " +
    "change tables, split checks, add discounts, update gratuity and taxes, or handle various other tasks.",
  FeatureList: null,
}, {
  Id: "table-tracking",
  Title: "TABLE TRACKING",
  SolutionIntro: null,
  FeatureList: [{
    title: "Manage the tables in your restaurant to see which tables are occupied, which are vacant, and how many people are at each table.",
    content: "",
  },{
    title: "Table Timer – Perfect for Pool Halls, Buffets, Bowling Alleys, and Bars that rent tables or rooms.",
    content: "",
  }],
}, {
  Id: "flexible-discount",
  Title: "FLEXIBLE DISCOUNT",
  SolutionIntro: null,
  FeatureList: [{
    title: "Cash Discount Program",
    content: "",
  },{
    title: "Eliminate up to 100% of your merchant’s processing fees by rewarding cash paying customers.",
    content: "",
  }],
}]