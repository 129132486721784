import * as yup from 'yup';
// import { isMobile } from "react-device-detect";
export const ContactSchema = yup.object().shape({
  customer_name: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  business_name: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: yup.string()
    .strict(true)
    .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, "Invalid phone number")
    .required("Required"),
  email: yup.string()
    .strict(true)
    .email("Invalid email")
    .required("Required"),
  title: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(20, "Too Long!"),
  website: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .url("Invalid URL")
    .min(2, "Too Short!")
    .max(100, "Too Long!"),
  address: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(100, "Too Long!"),
  city: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  us_states: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  zip: yup.string()
    .strict(true)
    .trim("Must be trimmed")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid Zip Code"),
});

export const StatesList = [
  "Alabama", "Alaska", "Arizona", "Arkansas",
  "California", "Colorado", "Connecticut",
  "Delaware", "Florida", "Georgia", "Hawaii",
  "Idaho", "Illinois", "Indiana", "Iowa",
  "Kansas", "Kentucky", "Louisiana",
  "Maine", "Maryland", "Massachusetts", "Michigan",
  "Minnesota", "Mississippi", "Missouri", "Montana",
  "Nebraska", "Nevada", "New Hampshire", "New Jersey",
  "New Mexico", "New York", "North Carolina", "North Dakota",
  "Ohio", "Oklahoma", "Oregon",
  "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia",
  "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

export const requestFormLeftList = {
  "mobile": [{
    fieldName: "customer_name",
    Title: "Your Name",
    star: true,
  }, {
    fieldName: "phone",
    Title: "Phone",
    star: true,
  }, {
    fieldName: "title",
    Title: "Title",
    star: false,
  }, {
    fieldName: "address",
    Title: "Address",
    star: false,
  }, {
    fieldName: "us_states",
    Title: "US States",
    star: true,
  }],
  "web": [{
    fieldName: "customer_name",
    Title: "Your Name",
    star: true,
  }, {
    fieldName: "business_name",
    Title: "Business Name",
    star: true,
  }, {
    fieldName: "phone",
    Title: "Phone",
    star: true,
  }, {
    fieldName: "email",
    Title: "Email",
    star: true,
  }, {
    fieldName: "title",
    Title: "Title",
    star: false,
  }, {
    fieldName: "website",
    Title: "Website",
    star: false,
  }]
}

export const requestFormRightList = {
  "mobile": [{
    fieldName: "business_name",
    Title: "Business Name",
    star: true,
  }, {
    fieldName: "email",
    Title: "Email",
    star: true,
  }, {
    fieldName: "website",
    Title: "Website",
    star: false,
  }, {
    fieldName: "city",
    Title: "City",
    star: true,
  }, {
    fieldName: "zip",
    Title: "Zip",
    star: false,
  }],
  "web": [{
    fieldName: "address",
    Title: "Address",
    star: false,
  }, {
    fieldName: "city",
    Title: "City",
    star: true,
  }, {
    fieldName: "us_states",
    Title: "US States",
    star: true,
  }, {
    fieldName: "zip",
    Title: "Zip",
    star: false,
  }]
};

export const requestFormMobileList = {
  "left": [{
    fieldName: "customer_name",
    Title: "Your Name",
    star: true,
  }, {
    fieldName: "phone",
    Title: "Phone",
    star: true,
  }, {
    fieldName: "title",
    Title: "Title",
    star: false,
  }, {
    fieldName: "address",
    Title: "Address",
    star: false,
  }, {
    fieldName: "us_states",
    Title: "US States",
    star: true,
  }],
  "right": [{
    fieldName: "business_name",
    Title: "Business Name",
    star: true,
  }, {
    fieldName: "email",
    Title: "Email",
    star: true,
  }, {
    fieldName: "website",
    Title: "Website",
    star: false,
  }, {
    fieldName: "city",
    Title: "City",
    star: true,
  }, {
    fieldName: "zip",
    Title: "Zip",
    star: false,
  }]
}

export const requestFormWebList = {
  "left": [{
    fieldName: "customer_name",
    Title: "Your Name",
    star: true,
  }, {
    fieldName: "business_name",
    Title: "Business Name",
    star: true,
  }, {
    fieldName: "phone",
    Title: "Phone",
    star: true,
  }, {
    fieldName: "email",
    Title: "Email",
    star: true,
  }, {
    fieldName: "title",
    Title: "Title",
    star: false,
  }, {
    fieldName: "website",
    Title: "Website",
    star: false,
  }],
  "right": [{
    fieldName: "address",
    Title: "Address",
    star: false,
  }, {
    fieldName: "city",
    Title: "City",
    star: true,
  }, {
    fieldName: "us_states",
    Title: "US States",
    star: true,
  }, {
    fieldName: "zip",
    Title: "Zip",
    star: false,
  }]
};

export const notice = {
  Title: "NOTICE",
  Content: [
    <br />, "Hotsauce POS Systems are sold only through our certified reseller channel.", <br />,
    "If you currently have a certified reseller, please continue working with them for the best sales/service experience.", <br />,
    "If you do not currently have a certified reseller helping you, " +
    "please let us know and we will have a reseller reach out to you shortly."
  ]
}

export const requestFormHint = [
  "Fields marked with an",
  <span className="star-mark fontsize-s">*</span>,
  " are required.",
  <br />,
  "Please provide information about you and your company so that we can properly deliver your quote."]

export const formikInitialValues = {
  customer_name: "",
  business_name: "",
  phone: "",
  email: "",
  title: "",
  website: "",
  address: "",
  city: "",
  us_states: StatesList[0],
  zip: "",
}

export const requestModalInfo = {
  default: "test",
  success: "Request sent sucessfully. We'll contact you as soon as possible.",
  false: "Request sent error.Please try it again."
}

export const contactInfo = {
  Title: "CONTACT",
  Content: [{
    category: "COMPANY",
    content: [
      {
        Title: "Corporate Office",
        Content: [
          "7001 Peachtree Industrial Blvd Suite 4200", <br />,
          "Norcross, GA 30092", <br />,
          "Tel: 678.325.5300"
        ]
      }, {
        Title: "Mexico Office",
        Content: [
          "Polanco, CDMX"
        ]
      }, {
        Title: "Philippine Office",
        Content: [
          "205 Amberland Plaza J. Vargas Avenue", <br />,
          "Ortigas Center Pasig City, Metro Manila 1600",
        ]
      }, {
        Title: "Taiwan Office",
        Content: [
          "356 Linsen N. Rd. ", <br />,
          "Zhongshan District, Taipei City 104",
        ]
      },
    ]
  }, {
    category: "OTHER",
    content: [{
        Title: "HotSauce Sales",
        Content: [
          "678.325.5300 opt #3", <br />,
          "sales@hotsaucepos.com",
        ]
      }, {
        Title: "HotSauce Support",
        Content: [
          "678.325.5300 opt #2", <br />,
          "support@hotsaucepos.com",
        ]
      },
    ]
  }]
}

export const careerInfo = {
  Title: "CAREER",
  Content: [
    "HotSauce Technologies is always on the look out for skillful and ambitious professions!", <br />,
    "We are actively seeking creative professionals who are serious about long term success in their career.", <br />,
    "A positive and energetic attitude is a must for all candidates.", <br />,
    "Email your resume and cover letter to: ",
    <span className="career-email fontsize-m">careers@hotsaucepos.com </span>
  ],
}

export const test = {
  "notice": {
    Title: "NOTICE",
    Content: [
      <br />, "Hotsauce POS Systems are sold only through our certified reseller channel.", <br />,
      "If you currently have a certified reseller, please continue working with them for the best sales/service experience.", <br />,
      "If you do not currently have a certified reseller helping you, please let us know and we will have a reseller reach out to you shortly."
    ]
  }

}
