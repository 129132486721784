
import { isMobile } from "react-device-detect";

export const clientCardinfo = [
  {
    Icon: "wine",
    Title: "BAR"
  },{
    Icon: "dinner",
    Title: "RESTAURANT"
  },{
    Icon: "beer",
    Title: "NIGHT CLUB"
  }
]

export const descriptionCardinfo = [
  {
    Title: "Employee Management",
    Image: "serviceImg",
    Icon: "inventory",
    Content:
      "Access a complete employee database, showing specific details on each staff member. " +
      "Track hours worked, edit timesheets, calculate overtime, evaluate sales performance and manage security levels."
  }, {
    Title: "Customer Database",
    Image: "businessImg",
    Icon: "mission",
    Content:
      "Quickly view customers’ order history and personal information. " +
      "Great for loyalty programs or for serving up the “regular.”"
  }, {
    Title: "Menu Customization",
    Image: "supportImg",
    Icon: "labor",
    Content:
      "Add, edit or remove menu items in real-time with a simple and intuitive interface. " +
      "Organize your menu items, categories, choice sets, and modifiers to suit your restaurant."
  }
]

export const hotsaucePOSCardinfo = [
  {
    Title: "Efficient Ordering",
    Image: "efficientOrder",
    AnchorHash: "#efficient-ordering",
  }, {
    Title: "Table Tracking",
    Image: "tableTracking",
    AnchorHash: "#table-tracking",
  }, {
    Title: "Flexible Discount",
    Image: "flexibleDiscount",
    AnchorHash: "#flexible-discount",
  }];

export const solutionCardinfo = [
  {
    Title: "Mission Control",
    Image: "missionControl",
    AnchorHash: "#mission-control",
  }, {
    Title: "Online Ordering",
    Image: "onlineOrder",
    AnchorHash: "#online-ordering",
  }, 
  {
    Title: "Gift Card / Loyalty",
    Image: "giftCard",
    AnchorHash: "#giftcards-loyalty",
  }, 
  {
    Title: "Mobile POS",
    Image: "mobilePOS",
    AnchorHash: "#mobile-POS",
  },
  // {
  //   Title: "Tableside Solution",
  //   Image: "tablesideSolution",
  //   AnchorHash: "#tableside-solution",
  // }, 
  {
    Title: "KDS",
    Image: "KDS",
    AnchorHash: "#KDS",
  },
  {
    Title: "Software Integration",
    Image: "softwareIntegrations",
    AnchorHash: "#software-integrations",
  }
];

export const deviceCardInfo = [
  {
    Title: "Hotsauce POS",
    Card: "hotsauce-pos",
    CardInfo: hotsaucePOSCardinfo,
  }, {
    Title: "SOLUTIONS",
    Card: "solutions",
    CardInfo: solutionCardinfo,
  },
]

export const introduction = [
  [
    "HotSauce Technologies has been the leading software provider for hospitality applications serving ",
    <span className={`introduction-emphasis ${!isMobile ? "fontsize-xl" : "fontsize-l"}`}> restaurants, bars</span>, " and ",
    <span className={`introduction-emphasis ${!isMobile ? "fontsize-xl" : "fontsize-l"}`}> nightclubs</span>, " since 1998."
  ],
  [
    "Headquartered in Atlanta, Georgia, the company has grown tremendously due to the incredible demands for a powerful, reliable," +
    "yet cost effective product. By utilizing the latest technology and stellar support, HotSauce Technologies has become the" +
    " leading provider of POS systems in the industry today."
  ]
]