import { Component } from "react";
import { withRouter } from "react-router-dom";
import { HotsaucePOSPreferredHardwareList } from "../../shared/PartnersPageConst";
import { ProductTable } from "../../components/Tables/index";
import { BackToTopButton } from "../../components/Button/index";
import "../../styles/EquipmentPage.css";
import { ReactComponent as Chilli } from "../../styles/img/Icons/chilli.svg";

class EquipmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount = () => {

  }

  renderEquipmentTable() {
    return (
      <div className="features-section" id="equipment">
        <div className="features-title fontsize-l">
          <Chilli className="chilli-icon" />
          Equipment
        </div>
        <div className="features-content fontsize-m">
          <div className="content">
            <ProductTable products={HotsaucePOSPreferredHardwareList}/>
            <div>
              <a className="product-link" 
                href={HotsaucePOSPreferredHardwareList.downloadLink}>
                {"Download the full document"}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="partners-background" id="partners">
        <div className="partners-content">
          {this.renderEquipmentTable()}
        </div>
        <BackToTopButton
          className="scroll-to-top-btn"
          icon={"arrow"} />
      </div>
    );
  }
}
export default withRouter(EquipmentPage);