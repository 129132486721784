export const solutionsGroup = [{
  Title: "Mission Control",
  AnchorHash: "#mission-control",
},
{
  Title: "Online Ordering",
  AnchorHash: "#online-ordering",
}, 
{
  Title: "Gift Cards / Loyalty",
  AnchorHash: "#giftcards-loyalty",
},
{
  Title: "Mobile POS",
  AnchorHash: "#mobile-POS",
},
// {
//   Title: "Tableside Solution",
//   AnchorHash: "#tableside-solution",
// }, 
{
  Title: "KDS",
  AnchorHash: "#KDS",
},
// {
//   Title: "Kiosk",
//   AnchorHash: "#kiosk",
// },
{
  Title: "Software Integrations",
  AnchorHash: "#software-integrations",
}];

export const featuresGroup = [{
  Title: "Front of House Features",
  AnchorHash: "#front-of-house-features",
},
{
  Title: "Manager Functions",
  AnchorHash: "#manager-functions",
}, 
{
  Title: "Back of House Functions",
  AnchorHash: "#back-of-house-functions",
},
{
  Title: "Back Office Reports",
  AnchorHash: "#back-office-reports",
}];

export const partnersGroup = [{
  Title: "Equipment",
  AnchorHash: "/equipment",
},
{
  Title: "Implementation",
  AnchorHash: "/implementation",
}, 
{
  Title: "Demos / Webinars",
  AnchorHash: "#demos-webinars",
},
{
  Title: "Sales Slicks",
  AnchorHash: "#sales-slicks",
},
{
  Title: "Best Fit",
  AnchorHash: "#best-fit",
},
{
  Title: "Downloadable Material",
  AnchorHash: "#downloadable-material ",
},
{
  Title: "RD Program",
  AnchorHash: "/rdprogram/english",
},
// {
//   Title: "FAQ's",
//   AnchorHash: "#faqs",
// }
];

export const faqsGroup = [{
  Title: "General",
  AnchorHash: "#general",
},
{
  Title: "Online Order",
  AnchorHash: "#online-ordering",
},
{
  Title: "Gift Card",
  AnchorHash: "#giftcards-loyalty",
},
{
  Title: "Reporting",
  AnchorHash: "#mission-control",
},
{
  Title: "PAX A920",
  AnchorHash: "#mobile-POS",
}, 
// {
//   Title: "KDS",
//   AnchorHash: "#KDS-faqs",
// },
// {
//   Title: "Kiosk",
//   AnchorHash: "#kiosk-faqs",
// },
// {
//   Title: "Software Integrations",
//   AnchorHash: "#software-integrations-faqs",
// }
];

export const NavInfo = [
  {
    Link: "/hotsauce-POS",
    Name: "HotSauce POS",
    SubNavOpen: false,
    SubNavInfo: null,
  },
  {
    Link: "/solutions",
    Name: "Solutions",
    SubNavOpen: true,
    SubNavInfo: solutionsGroup,
  },
  {
    Link: "/features-and-functions",
    Name: "Features & Functions",
    SubNavOpen: false,
    SubNavInfo: featuresGroup,
  },
  {
    Link: "/customers",
    Name: "Customer Portal",
    SubNavOpen: false,
    SubNavInfo: null,
  },
  {
    Link: "/partners",
    Name: "Partners",
    SubNavOpen: true,
    SubNavInfo: partnersGroup,
  },
  {
    Link: "/contact",
    Name: "Contact",
    SubNavOpen: false,
    SubNavInfo: null,
  },
  {
    Link: "/faqs",
    Name: "FAQs",
    SubNavOpen: true,
    SubNavInfo: faqsGroup,
  },
]