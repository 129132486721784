import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "../../styles/Slider.css";
import { 
  IoIosArrowDropleftCircle, 
  IoIosArrowDroprightCircle 
} from "react-icons/io";
import sliderOne from "../../styles/img/Slider/slider01.png";
import sliderTwo from "../../styles/img/Slider/slider02.png";
import sliderThree from "../../styles/img/Slider/slider03.png";
import sliderFour from "../../styles/img/Slider/slider04.png";
class Slider extends Component {
  render() {
    return (
      <div>
        <Carousel 
          autoPlay={true} 
          infiniteLoop={true}
          swipeable={true}
          emulateTouch={true}
          showStatus={false}
          showThumbs={false}
          stopOnHover={true} 
          transitionTime={500}
          interval={3000}
          width={"95%"}
          swipeScrollTolerance={5}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
            <Button className="arrow-btn arrow-left-btn" 
              onClick={onClickHandler} 
              title={label}>
              <IoIosArrowDropleftCircle className="arrow-icon iconsize-m"/>
            </Button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
            <Button className="arrow-btn arrow-right-btn" 
              onClick={onClickHandler} title={label}>
              <IoIosArrowDroprightCircle className="arrow-icon iconsize-m"/>
            </Button>
            )
          }>
          <div>
            <img 
              className="d-block"
              src={sliderOne}
              alt="First slide"
            />
          </div>
          <div>
            <img 
              className="d-block"
              src={sliderTwo}
              alt="Two slide"
            />
          </div>
          <div>
            <img 
              className="d-block"
              src={sliderThree}
              alt="Three slide"
            />
          </div>
          <div>
            <img 
              className="d-block"
              src={sliderFour}
              alt="Four slide"
            />
          </div>
        </Carousel>
      </div>
    )
  }
}
export default Slider;