import React, { Component, Suspense, lazy } from "react";
import ScrollToTop from "../../components/ScrollToTop";
import { Nav, Footer } from "../../components"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { 
  HomePage, 
  HotsaucePOSPage, 
  SolutionsPage, 
  ContactPage, 
  CustomersPage, 
  PartnersPage, 
  FeaturesPage,
  FaqsPage,
  ImplementationPage,
  EquipmentPage,
  PrivacyPolicyPage,
  RDProgramPage
} from "../../pages/index";
// const HomePage = lazy(() => import('./../../pages/HomePage/index.js'));
// const HotsaucePOSPage = lazy(() => import('./../../pages/HotsaucePOSPage/index.js'));
// const SolutionsPage = lazy(() => import('./../../pages/SolutionsPage/index.js'));
// const CustomersPage = lazy(() => import('./../../pages/CustomersPage/index.js'));
// const ContactPage = lazy(() => import('./../../pages/ContactPage/index.js'));
// const PartnersPage = lazy(() => import('./../../pages/PartnersPage/index.js'))
class AppRouting extends Component {
  routeMap = [
    {
      path: "/",
      component: <HomePage />,
      renderFooter: true
    },
    {
      path: "/hotsauce-POS",
      component: <HotsaucePOSPage />,
      renderFooter: true
    },
    {
      path: "/solutions",
      component: <SolutionsPage />,
      renderFooter: true
    },
    {
      path: "/features-and-functions",
      component: <FeaturesPage />,
      renderFooter: true,
    },
    {
      path: "/partners",
      component: <PartnersPage />,
      renderFooter: true
    },
    {
      path: "/partners/implementation",
      component: <ImplementationPage />,
      renderFooter: true
    },
    {
      path: "/partners/equipment",
      component: <EquipmentPage />,
      renderFooter: true
    },
    {
      path: "/rdprogram/spanish",
      component: <RDProgramPage />,
      renderFooter: true
    },
    {
      path: "/rdprogram/english",
      component: <RDProgramPage />,
      renderFooter: true
    },
    {
      path: "/customers",
      component: <CustomersPage />,
      renderFooter: true
    },
    {
      path: "/contact",
      component: <ContactPage />,
      renderFooter: false
    },
    {
      path: "/faqs",
      component: <FaqsPage />,
      renderFooter: false
    },
    {
      path: "/policy",
      component: <PrivacyPolicyPage />,
      renderFooter: false
    },
  ]
  render() {
    return (
      // <Suspense fallback={<p>Loading...</p>}>
        <BrowserRouter>
          <Nav />
          <ScrollToTop />
          <Switch>
            {this.routeMap.map(route => (
              // <Route key={route.path} path={route.path} 
              //   element={
              //     <>
              //       {route.component}
              //       {route.renderFooter ? <Footer /> : null}
              //     </>
              //   }>
              <Route key={route.path} exact element={route.component} path={route.path}>
                {route.component}
                {route.renderFooter ? <Footer /> : null}
              </Route>
            ))}
          </Switch>
        </BrowserRouter>
      // </Suspense >
    );
  }
}
export default AppRouting;