export const policyList = [{
  id: "",
  title: "Privacy Policy",
  policyIntro: "",
  policyItemList: [{
    title: "Privacy Policy",
    content: "HotSauce Technologies built the My HotSauce app as a Commercial app. " + 
    "This Service is provided by HotSauce Technologies and is intended for use as is.",
    iconEnabled: false,
  }, {
    title: "",
    content: "This page is used to inform visitors regarding our policies with the collection, use, " + 
    "and disclosure of Personal Information if anyone decided to use our Service.",
    iconEnabled: false,
  }, {
    title: "",
    content: "If you choose to use our Service, then you agree to the collection and use of " + 
    "information in relation to this policy. The Personal Information that we collect is used " + 
    "for providing and improving the Service. We will not use or share your information with " + 
    "anyone except as described in this Privacy Policy.",
    iconEnabled: false,
  }, {
    title: "Information Collection and Use",
    content: "For a better experience, while using our Service, we may require you to provide us " + 
    "with certain personally identifiable information, including but not limited to email addresses. " + 
    "The information that we request will be retained by us and used as described in this privacy policy.",
    iconEnabled: false,
  }, {
    title: "We may use Personal Data for the following purposes:",
    content: "",
    iconEnabled: false,
  }, {
    title: "",
    content: "To provide and maintain our Service, including to monitor the usage of our Service.",
    iconEnabled: false,
  },{
    title: "",
    content: "To manage your account: to manage your registration as a user of the Service. " + 
    "The Personal Information you provide can give you access to different functionalities of " + 
    "the Service that are available to you as a registered user." ,
    iconEnabled: false,
  }, {
    title: "",
    content: "To contact you: To contact you by email, telephone calls, SMS, or other equivalent " + 
    "forms of electronic communication.",
    iconEnabled: false,
  }, {
    title: "",
    content: "You have the right to request that We assist in updating or deleting the Personal " + 
    "Information that We have collected about you.",
    iconEnabled: false,
  }, {
    title: "Service Providers",
    content: "We may employ third-party companies and individuals due to the following reasons:",
    iconEnabled: false,
  }, {
    title: "",
    content: "To facilitate our Service;",
    iconEnabled: false,
  }, {
    title: "",
    content: "To provide the Service on our behalf;",
    iconEnabled: false,
  }, {
    title: "",
    content: "To perform Service-related services; or",
    iconEnabled: false,
  }, {
    title: "",
    content: "To assist us in analyzing how our Service is used.",
    iconEnabled: false,
  }, {
    title: "",
    content: "We want to inform users of this Service that these third parties " + 
    "have access to their Personal Information. The reason is to perform the tasks assigned " + 
    "to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
    iconEnabled: false,
  }, {
    title: "Security",
    content: "We value your trust in providing us your Personal Information, thus we are striving " + 
    "to use commercially acceptable means of protecting it. But remember that no method of transmission " + 
    "over the internet, or method of electronic storage is 100% secure and reliable, and we cannot " + 
    "guarantee its absolute security.",
    iconEnabled: false,
  }, {
    title: "Children’s Privacy",
    content: "These Services do not address anyone under the age of 13. We do not knowingly collect " + 
    "personally identifiable information from children under 13 years of age. In the case we discover " + 
    "that a child under 13 has provided us with personal information, we immediately delete this from " + 
    "our servers. If you are a parent or guardian and you are aware that your child has provided us with " + 
    "personal information, please contact us so that we will be able to do the necessary actions.",
    iconEnabled: false,
  }, {
    title: "Changes to This Privacy Policy",
    content: "We may update our Privacy Policy from time to time. Thus, you are advised to review " + 
    "this page periodically for any changes. We will notify you of any changes by posting the new " + 
    "Privacy Policy on this page.",
    iconEnabled: false,
  }, {
    title: "",
    content: "This policy is effective as of 2023-06-29",
    iconEnabled: false,
  }, {
    title: "Contact Us",
    content: "If you have any questions or suggestions about our Privacy Policy, " + 
    "do not hesitate to contact us at support@hotsaucepos.com.",
    iconEnabled: false,
  }]
}]