import { Component } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
// import { isMobile } from "react-device-detect";
import { Formik } from "formik";
import {
  ContactSchema,
  StatesList,
  WebinarList,
  BestFitContent,
  formikInitialValues,
  requestModalInfo,
  InformationList,
  WebinarLinkList,
  SalesSlicksLinkList,
} from "../../shared/PartnersPageConst";
import { ProductsCard } from "../../components/Card/index";
import "../../styles/PartnersPage.css";
import { ReactComponent as RegisterWebinarsAndDemos } from "../../styles/img/Icons/download_materials.svg";
import { ReactComponent as DownloadMaterials } from "../../styles/img/Icons/register_webinars_and_demos.svg";
import { ReactComponent as SalesSlicks } from "../../styles/img/Icons/sales_slicks.svg";
import { ReactComponent as Webinar } from "../../styles/img/Icons/webinar.svg";
class PartnersPage extends Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false,
      modalMessage: requestModalInfo.default,
      webinarsDisplay: false,
      downloadMaterialsDisplay: false,
      salesSlicksDisplay: false,
    };
  }

  svgDatabase = {
    registerWebinarsAndDemos: RegisterWebinarsAndDemos,
    downloadMaterials: DownloadMaterials,
    salesSlicks: SalesSlicks,
  };
  GoogleSheetAPI =
    "https://script.google.com/macros/s/AKfycbyS5gm1j_Xfw0UXYlxk2qlE4ra8yAMdliCh5PJPbWecFXnkazMVtQn0WkRn1tnFMFt9/exec";
  onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    console.table(values);
    await fetch(this.GoogleSheetAPI, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "content-type": "application/json",
      },
      cache: "no-cache",
      mode: "no-cors",
      redirect: "follow",
    })
      .then((r) => {
        resetForm({});
        this.setState({
          modalOpen: true,
          modalMessage: requestModalInfo.success,
        });
        // alert("Request sent");
        // setStatus({ success: true });
      })
      .catch((err) => {
        // setStatus({ success: false });
        setSubmitting(false);
        this.setState({
          modalOpen: true,
          modalMessage: requestModalInfo.false,
        });
        // setErrors({ submit: err.message });
        alert(`Error when sending, ${err.message}`);
      });
  };

  renderWebinarForm() {
    return (
      <div id="demos-webinars">
        <Formik
        initialValues={formikInitialValues}
        validationSchema={ContactSchema}
        onSubmit={this.onSubmit}
        >
          {(formikProps) => (
            <Form noValidate onSubmit={formikProps.handleSubmit}>
              {this.renderForm(formikProps)}
            </Form>
          )}
        </Formik>
        </div>
    );
  }

  renderWebinarLinkList = (WebinarLinkList) => {
    return (
      <>
        <div className="webinar-form-list-web">
          {WebinarLinkList.map((webinarLink) => {
            return (
              <div className="webinar-form-group">
                <div className="webinar-form-title fontsize-m">
                  {webinarLink.url !== "" ? (
                    <a className="webinar-link"
                      rel="noreferrer"
                      target="_blank"
                      href={webinarLink.url}>
                      {webinarLink.title}
                    </a>
                  ) : (
                    webinarLink.title
                  )}
                </div>
                {webinarLink.content && (
                  <div>
                    {webinarLink.content.map((subcontent) => {
                      return (
                        <div className="webinar-form-control fontsize-m">
                          {subcontent.url !== "" ? (
                            <a className="webinar-link"
                              rel="noreferrer"
                              target="_blank"
                              href={subcontent.url}>
                              {subcontent.title}
                            </a>
                          ) : (
                            subcontent.title
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="webinar-form-list-mobile">
          {WebinarLinkList.map((webinarLink) => {
            return (
              <div className="webinar-form-group">
                <div className="webinar-form-title fontsize-m">
                  {webinarLink.url !== "" ? (
                    <a className="webinar-link"
                      rel="noreferrer"
                      target="_blank"
                      href={webinarLink.url}>
                      {webinarLink.title}
                    </a>) : (
                    webinarLink.title
                  )}
                </div>
                {webinarLink.content && (
                  <div>
                    {webinarLink.content.map((subcontent) => {
                      return (
                        <div className="webinar-form-control fontsize-m">
                          {subcontent.url !== "" ? (
                            <a className="webinar-link"
                              rel="noreferrer"
                              target="_blank"
                              href={subcontent.url}>
                              {subcontent.title}
                            </a>
                          ) : (
                            subcontent.title
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </>
    )
  }

  renderFormList = (webinarformList, formikProps) => {
    return (
      <>
        <div className="webinar-form-list-web">
          {webinarformList.map((rowItem) => {
            return (
              <Form.Group
                key={rowItem.fieldName}
                as={Form.Col}
                className="webinar-form-group"
              >
                <Form.Label className="webinar-form-title fontsize-m">
                  {rowItem.Title}
                  {rowItem.star ? (
                    <span className="star-mark fontsize-m">*</span>
                  ) : null}
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={rowItem.fieldName === "us_states" ? "select" : "input"}
                    required={rowItem.fieldName !== "us_states" && rowItem.star}
                    id={rowItem.fieldName}
                    value={formikProps.values[rowItem.fieldName] ?? ""}
                    onChange={formikProps.handleChange}
                    className="webinar-form-control fontsize-m"
                    isValid={
                      formikProps.touched[rowItem.fieldName] &&
                      !formikProps.errors[rowItem.fieldName]
                    }
                  >
                    {rowItem.fieldName === "us_states"
                      ? StatesList.map((state, index) => {
                          return <option key={index}>{state}</option>;
                        })
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formikProps.errors[rowItem.fieldName]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            );
          })}
        </div>
        <div className="webinar-form-list-mobile">
          {webinarformList.map((rowItem) => {
            return (
              <Form.Group
                key={rowItem.fieldName}
                as={Form.Col}
                className="webinar-form-group"
              >
                <Form.Label className="webinar-form-title fontsize-m">
                  {rowItem.Title}
                  {rowItem.star ? (
                    <span className="star-mark fontsize-m">*</span>
                  ) : null}
                </Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={rowItem.fieldName === "us_states" ? "select" : "input"}
                    required={rowItem.fieldName !== "us_states" && rowItem.star}
                    id={rowItem.fieldName}
                    value={formikProps.values[rowItem.fieldName] ?? ""}
                    onChange={formikProps.handleChange}
                    className="webinar-form-control fontsize-m"
                    isValid={
                      formikProps.touched[rowItem.fieldName] &&
                      !formikProps.errors[rowItem.fieldName]
                    }
                  >
                    {rowItem.fieldName === "us_states"
                      ? StatesList.map((state, index) => {
                          return <option key={index}>{state}</option>;
                        })
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {formikProps.errors[rowItem.fieldName]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            );
          })}
        </div>
      </>
    );
  };

  renderAdditionalAndSubmit = (formikProps) => {
    return (
      <>
        <Form.Group className="webinar-form-additional">
          <Form.Label className="webinar-form-title fontsize-m">
            Additional Needs
          </Form.Label>
          <Form.Control
            id="addition_needs"
            as="textarea"
            value={formikProps.values["addition_needs"] ?? ""}
            onChange={formikProps.handleChange}
            className="additional-form-control fontsize-m"
          />
        </Form.Group>
        <div className="webinar-submit">
          <Button className="webinar-submit-btn fontsize-m" type="submit">
            Book
          </Button>
        </div>
        <Form.Group className="webinar-form-additional-mobile">
          <Form.Label className="webinar-form-title fontsize-m">
            Additional Needs
          </Form.Label>
          <Form.Control
            id="addition_needs"
            as="textarea"
            value={formikProps.values["addition_needs"] ?? ""}
            onChange={formikProps.handleChange}
            className="additional-form-control fontsize-m"
          />
        </Form.Group>
        <div className="webinar-submit-mobile">
          <Button className="webinar-submit-btn fontsize-m" type="submit">
            Book
          </Button>
        </div>
      </>
    );
  };

  renderForm = (formikProps) => {
    return (
      <>
        <div className="webinar-form-web">
          <div className="left-section">
            <Webinar className="webinar-icon" />
            {WebinarList.map((webinar, id) => {
              return(
                <div className="webinar-info">
                  <div className="partners-title fontsize-l">
                    {webinar.Title}
                  </div>
                  <div className="info-title fontsize-l">Date/Time</div>
                  <div className="webinar-content fontsize-l">
                    <p>{webinar.Date}</p>
                    <p>{webinar.StartTime}</p>
                  </div>
                  <div className="webinar-content fontsize-l">
                    {webinar.Content.map((content, idx) => {
                      return <span id={idx}>{content}</span>;
                    })}
                  </div>
                </div>
              )
            })}
            
          </div>
          <div className="right-section">
            <div className="partners-title fontsize-l">Demo / Webinar Links</div>
            <div className="webinar-hint-text fontsize-s">
              {this.renderWebinarLinkList(WebinarLinkList)}
            </div>
            {/* {this.renderFormList(WebinarFormWebList, formikProps)}
            {this.renderAdditionalAndSubmit(formikProps)} */}
          </div>
        </div>
        <div className="webinar-form-mobile">
          <div className="left-section">
            <div className="webinar-icon-section">
              <Webinar className="webinar-icon" />
            </div>
            {WebinarList.map((webinar, id) => {
              return(
                <div className="webinar-info">
                  <div className="partners-title fontsize-l">
                    {webinar.Title}
                  </div>
                  <div className="info-title fontsize-l">Date/Time</div>
                  <div className="webinar-content fontsize-l">
                    <p>{webinar.Date}</p>
                    <p>{webinar.StartTime + "-" + webinar.EndTime}</p>
                  </div>
                  <div className="webinar-content fontsize-l">
                    {webinar.Content.map((content, idx) => {
                      return <span id={idx}>{content}</span>;
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="right-section">
            <div className="partners-title fontsize-l">Demo / Webinar Links</div>
            <div className="webinar-hint-text fontsize-s">
              {this.renderWebinarLinkList(WebinarLinkList)}
            </div>
            {/* {this.renderFormList(WebinarFormWebList, formikProps)}
            {this.renderAdditionalAndSubmit(formikProps)} */}
          </div>
        </div>
      </>
    );
  };

  testClick = () => {
    fetch('/pdf/Whozz Calling- Caller ID Box.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'Whozz Calling- Caller ID Box.pdf';
          alink.click();
      })
  })
  }

  renderBestFits() {
    return (
      <div className="features-section">
        <div className="features-content fontsize-m">
          <div className="content">
            <ProductsCard
            productTitle={BestFitContent.title}
            productIntro={BestFitContent.intro}
            productFeaturesList={BestFitContent.bestFitList}
            faqEnabled={false} 
            learnMoreEnabled={false}
            displayImage={false}
            modal={"partner"}/>
          </div>
        </div>
      </div>
    );
  }

  openInNewTab = (info) => {
    const { webinarsDisplay, downloadMaterialsDisplay, salesSlicksDisplay } =
      this.state;
    this.setState({
      webinarsDisplay: info.Display === "webinars" ? !webinarsDisplay : false,
      downloadMaterialsDisplay:
        info.Display === "downloadMaterials"
          ? !downloadMaterialsDisplay
          : false,
      salesSlicksDisplay:
        info.Display === "salesSlicks" ? !salesSlicksDisplay : false,
    });
    if (info.Url) {
      window.open(info.Url, "_blank", "noopener,noreferrer");
    }
  };

  renderInfoList() {
    return (
      <div className="info-card-list">
        {InformationList.map((info, idx) => {
          const IconTagName = this.svgDatabase[info.Icon];
          return (
            <button
              className="info-card"
              id={info.id}
              onClick={() => this.openInNewTab(info)}
            >
              <div className="info-title fontsize-l">{info.Title}</div>
              <div className="info-icon fontsize-m">
                <IconTagName className="icon" />
              </div>
            </button>
          );
        })}
      </div>
    );
  }

  renderSalesSlicks = () => {
    return (
      <div>
        <div className="salesslicks-form-web" id="sales-slicks">
          <div className="left-section">
            <div className="info-icon fontsize-m">
              <SalesSlicks className="icon" />
            </div>
            <div className="info-title-sales fontsize-l">Sales Slicks</div>
            {SalesSlicksLinkList.map((salesSlick, id) => {
              return(
                <div className="salesslick-info">
                  <div className="salesslick-content fontsize-l">
                    {salesSlick.url !== "" ? (
                      <a className="webinar-link"
                        rel="noreferrer"
                        target="_blank"
                        href={salesSlick.url}>
                        {salesSlick.title}
                      </a> ) : (
                        salesSlick.title
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="salesslicks-form-mobile">
          <div className="left-section">
            <div className="info-icon fontsize-m">
              <SalesSlicks className="icon" />
            </div>
            <div className="info-title-sales fontsize-l">Sales Slicks</div>
            {SalesSlicksLinkList.map((salesSlick, id) => {
              return(
                <div className="salesslick-info">
                  <div className="salesslick-content fontsize-l">
                    {salesSlick.url !== "" ? (
                      <a className="webinar-link"
                        rel="noreferrer"
                        target="_blank"
                        href={salesSlick.url}>
                        {salesSlick.title}
                      </a> ) : (
                        salesSlick.title
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
     
    )
  }

  renderModal = () => {
    const { modalOpen, modalMessage } = this.state;
    return (
      <div
        className="modal-background"
        onClick={() => {
          this.setState({ modalOpen: false });
        }}
      >
        <Modal
          className="modal-box"
          show={modalOpen}
          onHide={() => {
            this.setState({ modalOpen: false });
          }}
          onExited={() => {
            this.setState({ modalOpen: false });
          }}
        >
          <Modal.Body className="modal-body fontsize-m">
            {modalMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="fontsize-m modal-btn"
              variant="ok"
              onClick={() => {
                this.setState({ modalOpen: false });
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  render() {
    return (
      <div className="partners-background" id="best-fit">
        <div className="partners-content">
          {this.renderBestFits()}
          {this.renderWebinarForm()}
          {this.renderSalesSlicks()}
        </div>
      </div>
    );
  }
}
export default PartnersPage;
