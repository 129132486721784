import { Component } from "react";
import { featuresList } from "../../shared/FeaturesPageConst";
import { ProductsCard } from "../../components/Card/index";
import { BackToTopButton } from "../../components/Button/index";
import { withRouter } from "react-router-dom";
import "../../styles/FeaturesPage.css";
class FeaturesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount = () => {
  }
  render() {
    return (
      <div className="product-background" id="features-and-functions" style={{ minHeight: '100%' }}>
        <div className="product-list" style={{ flexDirection: "column" }}>
          {featuresList.map((content) => {
            return (
              <ProductsCard
                key={content.Id}
                productId={content.Id}
                productTitle={content.Title}
                productIntro={content.SolutionIntro}
                productFeaturesList={content.FeatureList}
                faqEnabled={false}
                learnMoreEnabled={false}
                modal={"feature-and-function"}/>
            )
          })}
        </div>
        <BackToTopButton
          className="scroll-to-top-btn"
          icon={"arrow"} />
      </div>
    );
  }
}

export default withRouter(FeaturesPage);