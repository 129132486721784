import { Component, createRef } from "react";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { FiPlayCircle } from "react-icons/fi";
import { Button } from "react-bootstrap";
import {
  clientCardinfo,
  descriptionCardinfo,
  introduction,
  deviceCardInfo,
} from "../../shared/HomePageConst";
import { ClientCard, DeviceCard, DescriptionCard, } from "../../components/Card";
import ModalVideo from 'react-modal-video';
import { isMobile } from "react-device-detect";
import bannerImg from "../../styles/img/First Page/Landing Page-01-300k.png"
import bannerMobileImg from "../../styles/img/First Page/Home_mobile.png";
import "../../styles/HomePage.css";
class HomePage extends Component {
  constructor() {
    super();
    this.posScroll = createRef();
    this.solutionScroll = createRef();
    this.xUnit = 0;
    this.state = {
      isOpen: false,
      testState: 0,
      posHorizentalUnit: 0,
      solutionHorizentalUnit: 0,
    }
  }

  horizentalScroll = (listRef, horizentalUnit) => {
    if (this.posScroll.current && this.posScroll.current != null) {
      listRef.current.scrollTo({ left: horizentalUnit, behavior: "smooth" })
    }
  };
  
  componentDidMount = () => {
    const horizentalPOSUnit = Math.floor(this.solutionScroll.current.offsetWidth / 3);
    const horizentalSolutionUnit = Math.floor(this.solutionScroll.current.offsetWidth);
    this.setState({
      solutionHorizentalUnit: horizentalSolutionUnit,
      posHorizentalUnit: horizentalPOSUnit,
    })
    // const listHotsaucePOSWidth = document.getElementById('container-hotsauce-pos').getBoundingClientRect().width / 2;
    // const listSolutionsWidth = document.getElementById('container-solutions').getBoundingClientRect().width / 2;
    // const buttonHotsaucePOSLeft = document.getElementById('slideLeft-hotsauce-pos');
    // const buttonSolutionsLeft = document.getElementById('slideLeft-solutions');
    // const buttonHotsaucePOSRight = document.getElementById('slideRight-hotsauce-pos');
    // const buttonSolutionsRight = document.getElementById('slideRight-solutions');
    // let moveServiceAmount = 0;
    // let moveSolutionsAmount = 0;
    // buttonHotsaucePOSLeft.onclick = function () {
    //   moveServiceAmount -= listHotsaucePOSWidth;
    //   document.getElementById('container-hotsauce-pos').scrollTo({ left: moveServiceAmount, behavior: "smooth" });
    // };
    // buttonSolutionsLeft.onclick = function () {
    //   moveSolutionsAmount -= listSolutionsWidth;
    //   document.getElementById('container-solutions').scrollTo({ left: moveSolutionsAmount, behavior: "smooth" });
    // };
    // buttonHotsaucePOSRight.onclick = function () {
    //   moveServiceAmount += listHotsaucePOSWidth;
    //   document.getElementById('container-hotsauce-pos').scrollTo({ left: moveServiceAmount, behavior: "smooth" });
    // };
    // buttonSolutionsRight.onclick = function () {
    //   moveSolutionsAmount += listSolutionsWidth;
    //   document.getElementById('container-solutions').scrollTo({ left: moveSolutionsAmount, behavior: "smooth" });
    // };
  }

  renderClientCard = () => {
    return (
      <div className="client-card-list">
        {clientCardinfo.map((content) => {
          return (
            <ClientCard
              key={content.Title}
              icon={content.Icon}
              title={content.Title} />
          );
        })}
      </div>
    );
  }

  renderBanner = () => {
    return (
      <div className="banner">
        <img className="banner-img" src={bannerImg} alt="bannerImg" />
        <img className="banner-img-mobile" src={bannerMobileImg} alt="bannerImg" />
        <ModalVideo
          channel='youtube'
          isOpen={this.state.isOpen}
          videoId="reIO-WDdMsM"
          theme='dark'
          allowFullScreen={true}
          onClose={() => this.setState({ isOpen: false })} />
        <Button
          className="player-btn fontsize-m"
          onClick={() => { this.setState({ isOpen: true }) }}>
          <FiPlayCircle className="player-icon iconsize-m" />
          WATCH VIDEO
        </Button>
      </div>
    )
  }

  renderDescriptionCard = () => {
    return (
      <div className="description-card-list">
        {descriptionCardinfo.map((groupItem, index) => {
          return (
            <DescriptionCard
              key={groupItem.Title}
              numIndex={index}
              image={groupItem.Image}
              icon={groupItem.Icon}
              title={groupItem.Title}
              content={groupItem.Content} />
          );
        })}
      </div>
    );
  }

  renderDevicesCard = (title, cardType, infoList, index) => {
    const containerId = "container-" + cardType;
    const cardLeftId = "slideLeft-" + cardType;
    const cardRightId = "slideRight-" + cardType;
    const { posHorizentalUnit, solutionHorizentalUnit } = this.state;

    let scrollXUnit = cardType === 'hotsauce-pos' ? posHorizentalUnit : solutionHorizentalUnit;
    let listRef = cardType === 'hotsauce-pos' ? this.posScroll : this.solutionScroll;
    return (
      <div className="device-list" key={index}>
        <p className="device-title fontsize-xl">
          {title}
        </p>
        <div className="device-list-slider">
          <Button className="device-slide-btn"
            id={cardLeftId}
            onClick={() => { this.horizentalScroll(listRef, -scrollXUnit) }}>
            <IoIosArrowDropleftCircle className="arrow-icon iconsize-m" />
          </Button>
          <div className="device-card-list" id={containerId}
            ref={cardType === 'hotsauce-pos' ? this.posScroll : this.solutionScroll}
            style={{
              justifyContent: cardType === 'hotsauce-pos' ? 'space-between' : 'flex-start'
            }}>
            {infoList.map((listItem, index) => {
              return (
                <DeviceCard
                  key={listItem.Title}
                  groupName={cardType}
                  anchorHash={listItem.AnchorHash}
                  numIndex={index}
                  image={listItem.Image}
                  title={listItem.Title} />
              );
            })}
          </div>
          <Button
            className="device-slide-btn" id={cardRightId}
            onClick={() => { this.horizentalScroll(listRef, scrollXUnit) }}>
            <IoIosArrowDroprightCircle className="arrow-icon iconsize-m" />
          </Button>
        </div>
      </div>
    );
  }

  renderIntroduction = () => {
    return (
      <div className="introduction">
        {introduction.map((line, idx) => {
          return (
            <p key={idx} className="intro-section" >
              {line.map((content, contentIdx) => {
                return (
                  <span className={!isMobile ? "fontsize-xl" : "fontsize-l"}
                    key={contentIdx} >
                    {content}
                  </span>)
              })}
            </p>)
        })}
      </div>
    )
  }

  render() {
    // console.log("///" + this.state.solutionHorizentalUnit);
    // console.log("horizentalSolutionUnit: " + this.state.solutionHorizentalUnit);
    // console.log("horizentalPOSUnit: " + this.state.posHorizentalUnit)
    return (
      <div className="home-background" >
        <div className="home-content">
          {this.renderBanner()}
          {this.renderIntroduction()}
          {this.renderDescriptionCard()}
          {deviceCardInfo.map((device, idx) => {
            return this.renderDevicesCard(device.Title, device.Card, device.CardInfo, idx)
          })}
        </div>
      </div >
    );
  }
}

export default HomePage;