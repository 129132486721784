import * as yup from "yup";
// import { isMobile } from "react-device-detect";
export const ContactSchema = yup.object().shape({
  customer_name: yup
    .string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  business_name: yup
    .string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  phone: yup
    .string()
    .strict(true)
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Invalid phone number"
    )
    .required("Required"),
  email: yup.string().strict(true).email("Invalid email").required("Required"),
  address: yup
    .string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(100, "Too Long!"),
  city: yup
    .string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  us_states: yup
    .string()
    .strict(true)
    .trim("Must be trimmed")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  zip: yup
    .string()
    .strict(true)
    .trim("Must be trimmed")
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, "Invalid Zip Code"),
});

export const StatesList = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const WebinarFormWebList = [
  {
    fieldName: "customer_name",
    Title: "Your Name",
    star: true,
  },
  {
    fieldName: "business_name",
    Title: "Business Name",
    star: true,
  },
  {
    fieldName: "phone",
    Title: "Phone",
    star: true,
  },
  {
    fieldName: "email",
    Title: "Email",
    star: true,
  },
  {
    fieldName: "address",
    Title: "Address",
    star: false,
  },
  {
    fieldName: "city",
    Title: "City",
    star: true,
  },
  {
    fieldName: "us_states",
    Title: "US States",
    star: true,
  },
  {
    fieldName: "zip",
    Title: "Zip",
    star: false,
  },
];

export const BestFitContent = {
  title: "Best Fit for HotSauce POS",
  intro: "",
  bestFitList: [{
    title: "Full Service Restaurants",
    content: [
      "Full Service Restaurants are any hospitality business with table service and/or a server that takes your order at the table. They may or may not have a bar but it is the most common hospitality business. A deep complex menu is not uncommon as well as multi location print needs in food production areas",
      "Examples:	Steak House, Mexican Restaurant, Breakfast, Diner, Fine Dining.",
    ],
  }, {
    title: "Bar and Nightclubs",
    content: [
      "Unlike a bar at a restaurant, stand alone bars and nightclubs tend to focus on speed and performance, as well as Tab capbability. Menus can vary in size and complexity but speed/efficiency in ordering and processing of a transaction will always be a priority.",
    ],
  }, {
    title: "Quick Service Restaurants or \"Over The Counter\" Restaurants",
    content: [
      "Quick Service or Over The Counter service is the second most common business in the hospitality industry and is fastly becoming the most common. Their needs tend to center around customer interaction and Speed. Menus can vary in size and complexity. Accessories or add ons like Online Ordering, Kitchen Displays and QR Code Ordering can be a common request by these businesses to increase speed of service.",
      "Examples:	 Fast Food, Coffee Shop, Sandwich Shop, Deli, Ice Cream, Taqueria.",
    ],
  }]
};

export const PartnersContent = [{
  title: "Equipment",
  intro: "",
  partnerList: [{
    title: "",
    content: "",
  }]
}];

export const ImplementationProcessList = [{
  title: "HotSauce POS Implementation Process",
  intro: "We work directly with the merchant step by step. " + 
  "Welcome Email and Call, Menu Review, Training, Installation and System Go Live \n" + 
  "A full implementation, from Sale to Go Live,  can happen in 2 weeks but that is contingent on " + 
  "the merchant communicating the necessary information  and site readiness.",
  implementationList: [{
    title: "Welcome Email",
    content: [
      "A Welcome Email will be sent to the Merchant and the Agent listed on the account as well as a an accompanying Text Message. This is to establish communication with the merchant and set up a Welcome call. If the merchant does not respond, the implementation team will contact the agent. "
    ],
  }, {
    title: "Welcome Call",
    content: [
      "The purpose of the Welcome Call is to establish a relationship with the new HotSauce POS Merchant and set a specific timeline for the installation. Installations are performed: Monday - Thursday 8:00 am to 5:00 pm Eastern. The Implementation team will also ask a series of questions to help understand the needs of the merchant and assist in setting expectations of pos functionality inside this business. If the Merchant is not ready to schedule these meetings and/or is not ready to move forward with POS installation, it will be the responsibility of the Merchant and the Agent to reconnect with HotSauce at the time they are ready to move forward.",
      "A confirmation of all implementation meeting times and dates will be emailed to both the Merchant and the Agent of record."
    ]
  }, {
    title: "Menu Review",
    content: [
      "Implementation team will reach out at pre established time to review the entire menu, that was received and configured in the system. Merchant will need access to Computer/Laptop. It is recommended that the Day to Day Manager is involved in the Menu Review:  Items, Modifiers and Pricing will be reviewed.Any and All necessary changes will be documented on this call. If there are substantial changes and/or updates needed to the menu, a Second Menu Review may be scheduled before moving to the next step. Without a menu review to ensure that the menu is set up correctly and that all the prices have been added, the merchant and their staff will have issues using the system as they would like/need too. "
    ]
  }, {
    title: "Training",
    content: [
      "HotSauce POS Training is done via online video. This allows the merchant to training their staff individually and on their own timeline. Video Links will be provided to the merchant. Both English and Spanish are available. Onsite training is available with an additional fee."
    ]
  }, {
    title: "Site Readiness	",
    content: [
      "To ensure that the installation of the HotSauce POS goes smoothly and the system will function properly,  there are a few things that need to be in place and functioning properly before the installation can occur. The Hotsauce Implementation Team will work with the merchant and the agent to ensure that the brick and mortar site is ready to install the Hotsauce POS system.\n",
      "Requirements for POS Installation\n",
      "Desktop / Countertop installed and ready - at specific location, in building, where the terminal is being installed if applicable is there a 1 - 1/2\" minimum hole for cables to pass through\n" + 
      "Electrical outlet(s) powered on and within 5 feet of the computer's location\n" + 
      "DSL / Cable modem installed and operational - Is the Internet Functioning\n" + 
      "All necessary Computer network wiring  is run, setup and terminated (lines capped) This is CAT 5 Cabling or Ethernet Cabling. Hostsauce does not and cannot run ethernet cabling. A local company is recommended for this for long term support of this network.\n" + 
      "Remote Printer wiring setup and terminated (lines capped)",
    ],
  }, {
    title: "Installation",
    content: "The HotSauce Implementation Team will contract with a local installation tech to install the pos hardware on-site. This is just hotsauce pos hardware installation and will not cover wiring, third party equipment  and/or on-site training. The Hotsauce Implementation Team will be supporting the third party technician during the installation. All hotsauce supplied equipment will be set in place, connected and tested.  On-site installation by HotSauce Tech Team and/or  On-Site Training is available for an added fee. Any and all installations are only performed/completed: Monday - Thursday 8:00 am to 5:00 pm Eastern",
  }, {
    title: "Go Live",
    content: "\"Go Live\" is the first day the merchant will be using their new Hotsauce POS for the first time with live customers. The HotSauce Implementation team will contact the merchant the first day the merchant plans to use the system for transactions. This can happen the same day as the installation but is preferred that it occurs 1 to 2 hours before opening to ensure there is time to correct any issues that arise during this call. The team member and the owner or manager will run through the entire HotSauce POS system to ensure the system is functioning correctly. Order Placement, Processing Credit Card, Cash Drawer Opens, All Printers Work, End of Shift and End of Day Procedure, will all be reviewed with the management staff.",
  }, {
    title: "Follow Up",
    content: "The HotSauce Implementation team will do a Follow Up Call 3-5 days after the Go Live. This can be scheduled ahead of time and if there were many issues during the initial installation and/or go live, the call may be scheduled to occur before the 3 - 5 day window.",
  }]
}];

export const HotsaucePOSPreferredHardwareList = {
  title: "HOTSAUCE POS PREFERRED HARDWARE LIST",
  downloadLink: "https://drive.google.com/uc?export=download&id=1HcNZjMlEelp_SBXjYAclJLS03KmdPaGw",
  hardwares: [{
    title: "POS Terminal",
    descriptions: [{
      content: "Partnertech GRACE G4 TOUCH POS SYSTEM",
      link: "https://www.partnertechcorp.com/wp-content/uploads/2020/03/PTU-G4-Series-200311.pdf",
    },{
      content: "Partnertech SP550 TOUCH POS SYSTEM",
      link: "https://www.partnertechcorp.com/wp-content/uploads/2017/04/PTU-SP-550-042617.pdf",
    }],
  },{
    title: "Receipt / Thermal Printer",
    descriptions: [{
      content: "Partnertech RP-630",
      link: "https://www.partnertechcorp.com/wp-content/uploads/2018/08/PTU-RP-630.pdf",
    }],
  },{
    title: "Kitchen / Dot Matrix Printer",
    descriptions: [{
      content: "SNBC M-300",
      link: "https://www.crs-usa.com/Files/CRS/Marketing/BTP-M300/Brochure_BTP-M300.pdf?download=false",
    },{
      content: "Epson TM-U220B",
      link: "https://files.support.epson.com/pdf/pos/bulk/tm-u220_ds_cpd-41758.pdf",
    }],
  },{
    title: "Cash Drawer",
    descriptions: [{
      content: "Partnertech CDR-6E410",
      link: "https://www.partnertechcorp.com/wp-content/uploads/2017/04/PTU-SP-550-042617.pdf",
    }],
  },{
    title: "Scale",
    descriptions: [{
      content: "Avery Berkle 6712",
      link: "https://sep.yimg.com/ty/cdn/yhst-44281417917957/6712_bro.pdf",
    }],
  },{
    title: "Barcode Scanner",
    descriptions: [{
      content: "Honeywell 1250G-2USB",
      link: "https://cdn.barcodesinc.com/themes/barcodesinc/pdf/Honeywell/1250g.pdf",
    }],
  },
  // {
  //   title: "Kiosk",
  //   descriptions: [{
  //     content: "Partnertech ALFRED AD-215/156",
  //     link: "https://www.partnertechcorp.com/wp-content/uploads/2021/03/PTU-Alfred-Data-Sheet-W_A-210331.pdf",
  //   }],
  // },
  {
    title: "Server Specification",
    descriptions: [{
      content: "CPU: AMD Athlon 3000G, AMD Athlon 200GE or better. Intel i5 or better.\n" + 
      "8 GB of RAM or higher\n" + 
      "HDD: 500GB or higher. 7200 RPM or faster. (HDD ST1000NM0008)\n" + 
      "Windows 10",
    }],
  },{
    title: "Routers",
    descriptions: [{
      content: "MikroTik hEX RB750Gr3",
      link: "https://i.mt.lv/cdn/product_files/hEX_161115.pdf",
    },{
      content: "MikroTik hEX RB760iGS",
      link: "https://i.mt.lv/cdn/product_files/hEX__S_191220.pdf",
    },{
      content: "TP-Link ER605",
      link: "https://static.tp-link.com/upload/product-overview/2022/202201/20220121/ER605(UN)2.0_Datasheet.pdf",
    }],
  },{
    title: "Switches",
    descriptions: [{
      content: "Any Gigabit switch with throughput up to 1000 Mbps.\n" + 
      " \"Fast\" switches are too slow, and max out at 100 Mbps.",
    },{
      content: "TP-Link Litewave 5 Port",
      link: "https://static.tp-link.com/2021/202103/20210326/LiteWave%20Switch%20Datasheet.pdf",
    },{
      content: "NETGEAR GS205",
      link: "https://www.downloads.netgear.com/files/GDC/datasheet/en/200-Series_DS.pdf",
    }],
  },{
    title: "Wireless Access Point",
    descriptions: [{
      content: "TP-Link EAP225 V3",
      link: "https://static.tp-link.com/upload/product-overview/2022/202212/20221212/EAP%20Datasheet%20(full%20version).pdf",
    },{
      content: "Ubiquiti UNIFI AP AC",
      link: "https://dl.ubnt.com/datasheets/unifi/UniFi_AC_APs_DS.pdf",
    }],
  },{
    title: "Kitchen Display System KDS",
    descriptions: [],
  },{
    title: "Control Computer",
    descriptions: [{
      content: "Logic Control LS8900",
      link: "https://logiccontrols.com/wp-content/uploads/2021/07/LS-8900-EN_2.1-1.pdf",
    }],
  },{
    title: "Bump Bar",
    descriptions: [{
      content: "Logic Control KB9000",
      link: "https://logiccontrols.com/wp-content/uploads/2016/01/KB9000_english_specsheet.compressed-1.pdf",
    }],
  },{
    title: "Monitor Screen",
    descriptions: [{
      content: "Monitors are sold separately- Any Wall Mountable Screen with HDMI capability will function",
    }],
  },{
    title: "Caller ID",
    descriptions: [],
  },{
    title: "Call ID Control Box",
    descriptions: [{
      content: "Whozz Calling - 2 Line or 4 Line - Analog Deluxe Model - Serial Port",
      link: "https://drive.google.com/uc?export=download&id=1EJPPU1Ie67kphRfQwSshEPphBsNE0UAY",
    }],
  }]
}

export const WebinarFormHint = [
  "Fields marked with an",
  <span className="star-mark fontsize-s">*</span>,
  " are required.",
  <br />,
  "Please provide information about you and your company so that we can properly deliver your quote.",
];

export const formikInitialValues = {
  customer_name: "",
  business_name: "",
  phone: "",
  email: "",
  address: "",
  city: "",
  us_states: StatesList[0],
  zip: "",
};

export const requestModalInfo = {
  default: "test",
  success: "Request sent sucessfully. We'll contact you as soon as possible.",
  false: "Request sent error.Please try it again.",
};

export const InformationList = [
  {
    id:"sales-slicks",
    Title: "Sales Slicks",
    Icon: "salesSlicks",
    Display: "salesSlicks",
    Content: "",
  },
  {
    id:"downloadable-material",
    Title: "Downloadable Materials",
    Icon: "downloadMaterials",
    Display: "downloadMaterials",
    Url: "",
  },
  {
    id: "demos-webinars",
    Title: "Demos & Webinars",
    Icon: "registerWebinarsAndDemos",
    Display: "webinars",
    Url: "",
  },
];

export const SalesSlicksLinkList = [{
  title: "Bar Slick",
  url: "",
  content: [{
    title: "",
    url: "",
  }]
}, {
  title: "Quick Service",
  url: "https://drive.google.com/uc?export=download&id=1qyJMs7t5xFtW3X3R6xRuwxYo7iuEs2O8",
  content: [{
    title: "",
    url: "",
  }]
}, {
  title: "Table Service",
  url: "https://drive.google.com/uc?export=download&id=1hj4bTiK-f8MC2x1oVmGkRU_UmkCq8zsS",
  content: [{
    title: "",
    url: "",
  }]
}, {
  title: "Universal",
  url: "https://drive.google.com/uc?export=download&id=1eQt9rdTJUGdCMmsgJQGvYvdgiyvYSg5c",
  content: [{
    title: "",
    url: "",
  }]
}];

export const WebinarList = [{
  Title: "Full-Service Restaurants",
  Date: "Tuesdays",
  StartTime: "10:00 AM EDT",
  EndTime: "",
  Content: [],
}, {
  Title: "Counter Service Restaurants",
  Date: "Wednesdays",
  StartTime: "10:00 AM EDT",
  EndTime: "",
  Content: [],
}, {
  Title: "Full-Service Restaurants",
  Date: "Thursdays",
  StartTime: "04:00 PM EDT",
  EndTime: "",
  Content: [],
}, {
  Title: "Full-Service Restaurants",
  Date: "Fridays",
  StartTime: "01:00 PM EDT",
  EndTime: "",
  Content: [],
}, {
  Title: "Counter Service Restaurants",
  Date: "Fridays",
  StartTime: "04:00 PM EDT",
  EndTime: "",
  Content: [],
}];

export const WebinarLinkList = [{
  title: "Full-Service Webinar Registration",
  url: "https://us02web.zoom.us/webinar/register/WN_2ZTt3kydSB2Db-JQNTz67g",
  content: [{
    title: "There will be a Q&A session after a 45 minutes presentation on the software. We will start the demo 5 minutes after the hour. If you miss the login period you will need to reschedule.",
    url: "",
  }]
}, {
  title: "Counter Service Webinar Registration",
  url: "https://us02web.zoom.us/webinar/register/WN_2z-fqI39Th-eBrK7L1uinQ",
}, {
  title: "Demo Files",
  url: "",
  content: "",
}, {
  title: "Spanish Demo Recording",
  url: "https://www.youtube.com/watch?v=BwZnp2WWAfY",
  content: "",
}, {
  title: "Training Videos",
  url: "",
  content: [{
    title: "Full Service - English",
    url: "https://www.youtube.com/watch?v=ZPyatPJ0cek",
  }, {
    title: "Full Service - Spanish",
    url: "https://youtu.be/OyBimljR2jc",
  }, {
    title: "Quick Counter Service / Bar - English",
    url: "https://www.youtube.com/watch?v=Brg5rIwokSA",
  }, ],
}, ];

// {
//   Title: "Webniar Topic",
//   Date: "07/30/2022",
//   StartTime: "14:00",
//   EndTime: "18:00",
//   Content: ["Lorem ipsum dolor sit amet, consetetur sadipscing elitr,",
//     " sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
//     " sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ",
//     "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//     " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."]
// },
// {
//   Title: "Webniar Topic",
//   Date: "07/30/2022",
//   StartTime: "14:00",
//   EndTime: "18:00",
//   Content: ["Lorem ipsum dolor sit amet, consetetur sadipscing elitr,",
//     " sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
//     " sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ",
//     "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//     " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."]
// },

// export const WebinarList = [
//   {
//     Title: "Webniar Topic",
//     Date: "07/30/2022",
//     StartTime: "14:00",
//     EndTime: "18:00",
//     Content: ["Lorem ipsum dolor sit amet, consetetur sadipscing elitr,",
//       " sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
//       " sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ",
//       "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//       " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."]
//   },
//   // {
//   //   Title: "Webniar Topic",
//   //   Date: "07/30/2022",
//   //   StartTime: "14:00",
//   //   EndTime: "18:00",
//   //   Content: ["Lorem ipsum dolor sit amet, consetetur sadipscing elitr,",
//   //     " sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
//   //     " sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ",
//   //     "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//   //     " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."]
//   // },
//   // {
//   //   Title: "Webniar Topic",
//   //   Date: "07/30/2022",
//   //   StartTime: "14:00",
//   //   EndTime: "18:00",
//   //   Content: ["Lorem ipsum dolor sit amet, consetetur sadipscing elitr,",
//   //     " sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,",
//   //     " sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ",
//   //     "Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//   //     " Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."]
//   // },
// ]
