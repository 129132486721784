import { Component } from "react";
import { withRouter } from "react-router-dom";
import { ImplementationProcessList } from "../../shared/PartnersPageConst";
import { ProductsCard } from "../../components/Card/index";
import { BackToTopButton } from "../../components/Button/index";
import "../../styles/ImplementationPage.css";

class ImplementationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  renderImplementationProcess() {
    return (
      <div className="product-background" style={{ minHeight: '100%' }}>
        <div className="product-list" style={{ flexDirection: "column" }}>
          {ImplementationProcessList.map((content) => {
            return (
              <ProductsCard
                key={content.Id}
                productId={content.Id}
                productTitle={content.title}
                productIntro={content.intro}
                productFeaturesList={content.implementationList}
                faqEnabled={false} 
                learnMoreEnabled={false}
                modal={"partner"}/>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="partners-background" id="partners">
        <div className="partners-content">
          {this.renderImplementationProcess()}
        </div>
        <BackToTopButton
          className="scroll-to-top-btn"
          icon={"arrow"} />
      </div>
    );
  }
}

export default withRouter(ImplementationPage);