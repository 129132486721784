import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { HashLink } from "react-router-hash-link";
import missionControlImg from "../../styles/img/Solution/MissionControl-100k.png";
import mobilePOSImg from "../../styles/img/Solution/MobilePos-100k.png";
import onlineOrderImg from "../../styles/img/Solution/OnlineOrder-100k.png";
import tablesideSolutionImg from "../../styles/img/Solution/Tablet.jpg";
import KDSImg from "../../styles/img/Solution/KDS-100k.png";
import efficientOrderImg from "../../styles/img/HotsaucePOS/EfficientOrder-100k.png";
import flexibleDiscountImg from "../../styles/img/HotsaucePOS/FlexibleDiscount-100k.png";
import tableTrackingImg from "../../styles/img/HotsaucePOS/TableTracking-100k.png";
import giftCardImg from "../../styles/img/Solution/GiftCard.jpg";
import { ReactComponent as Chilli } from "../../styles/img/Icons/chilli.svg";
import { ReactComponent as Fire } from "../../styles/img/Icons/fire.svg";
import "../../styles/ProductsCard.css";

class ProductsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardModal: this.props.modal ? this.props.modal : "original",
      titleClassName: this.props.modal ? " features-description-title": "",
    }
  }
  renderFeatures = (featureList) => {
    return (
      <div >
        {featureList.map((feature, idx) => {
          return (
            <div className="feature-content" key={idx}>
              <Fire className="fire-icon" />
              <span className={"feature-description fontsize-l" + this.state.titleClassName}>
                {feature.title}
              </span>
              <span className="feature-description" />
              <span className="feature-description fontsize-l">
                {feature.content}
                {feature.links && feature.links.map((link, idx) => {
                  return (
                    <div>{link.title}</div>
                  )
                })}
              </span>
            </div>
          );
        })}
      </div>
    )
  }

  render() {
    const { 
      productId, 
      productTitle, 
      productIntro, 
      productFeaturesList, 
      faqEnabled, 
      learnMoreEnabled,
      displayImage,
    } = this.props;
    const imgDatabase = {
      "mission-control": missionControlImg,
      "online-ordering": onlineOrderImg,
      "mobile-POS": mobilePOSImg,
      "tableside-solution": tablesideSolutionImg,
      "KDS": KDSImg,
      "efficient-ordering": efficientOrderImg,
      "flexible-discount": flexibleDiscountImg,
      "table-tracking": tableTrackingImg,
      "giftcards-loyalty": giftCardImg,
    };
    return (
      <div id={productId} className="product-content">
        <div className="product-title-section">
          {displayImage && (
            <img className="product-img" src={imgDatabase[productId]} alt={productId} />
          )}
          <div className="product-title">
            <Chilli className="chilli-icon" />
            <p className="fontsize-l">
              {productTitle}
            </p>
          </div>
          {productIntro != null ? <p className="product-intro fontsize-l">{productIntro}</p> : null}
          {productFeaturesList != null ? this.renderFeatures(productFeaturesList) : null}
          <div className='product-section-btns'>
            {faqEnabled && (
              <HashLink className="navigation-faq-link" key={productId} smooth="true" to={"faqs#" + productId}>
                <Button className={`navigation-btn ${!isMobile ? "fontsize-m" : "fontsize-s"}`}>
                  FAQs
                </Button>
              </HashLink> 
            )}
            {learnMoreEnabled && (
              <Link smooth="true" to="/contact" className="navigation-more-link">
                <Button className={`navigation-btn ${!isMobile ? "fontsize-m" : "fontsize-s"}`}>
                  LEARN MORE
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default ProductsCard;