//This is the old data structure
// export const featuresList = [{
//   Id: "front-of-house-features",
//   Title: "FRONT OF HOUSE FEATURES",
//   SolutionIntro:"",
//   FeatureList: [
//     "Cashier Banking – Servers can use their own logins for placing orders while cashiers can handle " + 
//     "all payments for customers on their way out.  Cash will report on the cashiers reports while still " + 
//     "allowing credit cards tips to go to the appropriate server.",

//     "Server Banking – Servers are responsible for taking payments on their own orders and all order and " + 
//     "payment information stays with the employee who created the order.",

//     "Split Items – Easily and quickly split  appetizers or shared plates between 2 or more guests allowing " + 
//     "everyone to pay their share, on their own check.",

//     "Split Check - Easily and quickly split a check into multiple payments or individual checks for 2 or " + 
//     "more guests at the same table.",

//     "Seat Number Management - Assigning seat numbers to each customer at a table makes it easier to service " + 
//     "a table more easily by multiple staff members.",

//     "Unlimited Item Modifiers – Adjust specific items via attached  modifier bank for easy access or else type " + 
//     "in special instructions to make sure orders are prepared properly.",

//     "Unlimited Universal Modifiers - A complete list of adjustments that can be used on any Check, Tab or Item at " + 
//     "any time. i.e. Sauce On Side, Extra plate, To Go, etc.",

//     "Pizza Modifier - Left Half / Right Half - The ability to add topping to a specific half of a pizza and communicate " + 
//     "that to the kitchen as well as having separate pricing for the adjusted portion size: Small - Medium - Large.",

//     "Quick Keys or Hot Keys for Quick Access – Keep specific items, modifiers or instructions always available to your " + 
//     "employees regardless of what menu category they are currently viewing. ",

//     "Reorder button – Order another round for your table without needing to redo modifiers or double check what you’ve entered.",
//     "Remove Button – Servers can easily correct mistakes they’ve made without needing to get a manager as long as the " + 
//     "items haven’t printed out in the Bar\\Kitchen.",

//     "Send 1st Functions – Servers can easily choose when to send items to Kitchen or Bar printers without exiting from " + 
//     "the entire order.  Easily get appetizers on their own separate tickets and a round of drinks printing while still " + 
//     "entering in the entrees all done on one screen.",

//     "Meal Coursing - Course out a table, setting up all five courses on one ticket.  Easily send “Fire Tickets” back " + 
//     "to the kitchen whenever the customer is ready for their next course.",

//     "Hold / Fire  and Timed Hold – You can enter an item but “Hold” it until the customer is ready.  Fire (unhold)  the " + 
//     "items when you want it to print, or set a timer and it will unhold and print automatically.",

//     "QR Code Ordering – We can provide QR codes for your online ordering website to be placed on coupons or to-go menus " + 
//     "to give your customers easy access.  We can also provide QR codes for specific tables.  Now the customers can place " + 
//     "an order when they first sit down without even needing to speak with a server.  It prints in the kitchen and notifies " + 
//     "employees on FOH that a new order is on the table.",

//     "A920 Order and Pay at the Table – With the A920 chip reader not only can you take payments table side but also starting " + 
//     "tables creating the order and adding to it can all be done from a handheld chip reader.",

//     "Merge Tables - take two open table or checks and combine them to one.",

//     "Happy Hour/Alternate Pricing - Secondary pricing that specific items automatically change to during a " + 
//     "predetermined time period.",
    
//     "Quantity Ordering - order any item in multiple quantities opposed to one at a time.",

//     "Fast Bar - Create an order and process the tender for one screen. This is mostly used in a fast pace bar " + 
//     "environment but can be used for quick service restaurants.",

//     "BarTab - hold customer information to enable continued addition to an open check without opening a table.",

//     "Chip Reader Bar Tabs – Start a bar tab by inserting the card into the chip reader and you can hand it back to " + 
//     "the customer without needing it again for payment.",
    
//     "Bar Printing / Remote Printing - Send orders to your bar or any other production location in your restaurant " + 
//     "without needing a full pos station at that location.",

//     "Gift Cards -Hotsauce Gift Card program allows the user to control their own internal gift card program and " + 
//     "tracking of the purchase and amounts. Gift card can be purchased directly from Hotsauce Technologies.",

//     "Guest Loyalty - Track customer sales to allow them to accumulate points that they can redeem for goods  " + 
//     "and/or services provided by your business.",

//     "Future Orders - pre set / schedule orders in the system that will automatically be sent to kitchen st the " + 
//     "predetermined time and date.",

//     "Driver Routing - Assign orders to drivers, track which drivers are out on delivery and which have checked back in. " + 
//     " Get one report per driver at the end of the night that has their sales and tips already sorted.",

//     "Item Recipes Accessibility  - Make servers aware of what ingredients are in each dish to help with " + 
//     "customers’ allergies.  Allows bartenders to check and print drink recipes if they are unfamiliar.",

//     "Gratuity \\ Auto Gratuity - Easily add gratuity and adjust a ticket for finalization as well as instituting a " + 
//     "threshold for gratuity to be added to a check automatically.",

//     "Tax/Inclusive Tax/Smart Tax - the ability to have any item prices as a flat dollar amount ( $1.00, $5.00 - $10.00 ) " + 
//     "include the required taxes due. The POS reporting will adjust the total amount from the taxes for financial report.",

//     "House Accounts - allow customers to charge directly to a premade account that can be paid down at any time in the future.",

//     "Cash Discount / Non Cash Fee - The ability to add a small percentage to an transaction to cover the costs " + 
//     "associated with credit card processing.",

//     "Fingerprint Readers - increase the security of staff access to your pos with the addition of fingerprint " + 
//     "identification readers to a system.",
//   ],
// }, {
//   Id: "manager-functions",
//   Title: "MANAGER FUNCTIONS",
//   SolutionIntro:"",
//   FeatureList: [
//     "Manager Cards - Control access to manager permissions with the use of manager cards. This will ensure that " + 
//     "staff can not know or discover manager access codes.",

//     "Customizable Dining Room Display - Dining room displayed on screen can be created to match in-house setup for " + 
//     "easier orientation and tracking of tables in use.",

//     "86 Item Management – 86 Items (mark them out of stock) with a few buttons and prevent employees from ordering " + 
//     "items you don’t have.  Employees are made aware during clock-in of what items aren’t available and can easily " + 
//     "print out the list at any time to keep in their server pad for referencing during their shift.",

//     "Menu Item Countdown – Track items counts and make employees aware of limited availability.  Enter a countdown " + 
//     "of an item before a busy rush and don’t worry about when you run out.  The system will automatically add the item " + 
//     "to the 86 list and employees can’t order it until a manager resets the count.",

//     "Real Time Labor Reports – Track labor percentages mid-shift to see if it’s time to start cutting people.",

//     "Real Time Sales Reports – Track sales throughout the day to see how you’re doing.  Sales can be emailed or texted " + 
//     "at preset times without needing to contact anyone onsite for the information.",

//     "Price Change from Front of the House – Easily edit item prices from the FOH software in case you made a mistake " + 
//     "setting up an item or just starting to offer an existing item as a special.",

//     "All Table Function – Managers or owners can easily see what’s going on in the entire restaurant and add or edit " + 
//     "orders without needing to go through the table’s server.",

//     "Voids –Use Void if you made a mistake or were testing an item.  It will zero the cost and remove the item from any " + 
//     "inventory reports",

//     "Comps – Use comp if food is wasted, spilled or made incorrectly.  The item is zeroed out but will still " + 
//     "report to inventory.",

//     "Discounts – Discount items on the fly by percentage or dollar amount.",

//     "Transfer Tables\\Guests – Easily transfer tables between employees or to a different table.  Transfer individual " + 
//     "guests if not everyone moves.",

//     "Profit Center Tracking – Track where your sales are made within your business.  Simple profit centers may be Delivery, " + 
//     "Carryout or Main Dining, but you can get more specific per dining room, or even table.  Track your Patio vs Banquet " + 
//     "vs Cocktail tables to see where customers prefer to dine.",

//     "End of Day Reports – Simplify your closing process by setting up Autobatch and EOD reports.  Your POS will " + 
//     "automatically settle all credit card trx and then email you, managers and\\or accountants with a wide variety of " + 
//     "reports automatically.",

//     "Server Revenue Reports – Track employee’s sales over date ranges or monitor their sales in real time to see who your " + 
//     "producers are and those who may need more training.",

//     "Online Order Maintenance – If online orders are backing up you can easily disable or change your estimated wait times " + 
//     "from FOH to better inform your customers.",

//     "Customer Database - Store customer information based on telephone numbers.  This allows reordering of previous items, " + 
//     "saving special requests, view their sales history or search and filter your database to hone in on specific demographics.",

//     "House Account Management - Extend credit to frequent customers, friendly businesses or schools.  House accounts allow " + 
//     "the order to be closed without payment and then invoice the customer later on.  This encourages repeat sales and is " + 
//     "crucial for catering\\delivery.",

//     "Guest Count Analytics - Easily break down sales by number of guest per day, week, hour.",

//     "Employee Messaging - Managers can communicate with the entire or individual staff members. i.e. Today's Special, " + 
//     "We are out of.. , Please come talk to me after your shift.",

//     "Item Cost Manager - Input the cost of each item.  Track on product mix reports and get a theoretical food cost that " + 
//     "factors in sales and comps of an item.",
//   ],
// }, {
//   Id: "back-of-house-functions",
//   Title: "BACK OF HOUSE FUNCTIONS",
//   SolutionIntro: "",
//   FeatureList: [
//     "Expo Printing – Items can be sent to specific stations within the kitchen without printing on tickets sent to " + 
//     "different kitchen stations.  An Expo printer can be set up to receive all items and make sure the Expediter " + 
//     "trays up everything correctly.",

//     "Multiple Print Zones/ Multiple Kitchen Printers – Set items to only print to their specific station in the " + 
//     "kitchen without including all items on the order.",

//     "Kitchen Ticket Consolidation – Easily group together like items to reduce ticket length and make it easier for " + 
//     "your kitchen manager to call orders.",

//     "Kitchen Display Systems ( KDS ) – Screens along with a bump bar allow your cooks to manage orders without any " + 
//     "tickets involved.  You can also use a combination of Kitchen Display systems and printers if you’d still like " + 
//     "tickets for specific stations expo.",

//     "Kitchen Ticket Summary – Summarize an entire order for expo; or summarize items sent to other kitchen stations " + 
//     "without giving unnecessary details.  (Ex=The flattop doesn’t need to know the toppings, sauce or details of " + 
//     "the pizzas cooking, but he does need to know that 2 pizzas are going out with the hamburgers he’s making.)",

//     "Online Ordering – Hotsauce will build and maintain an online ordering website for you.  Orders placed automatically " + 
//     "print in the kitchen.  Customizable design for the web layout, upload pictures of your menu items and display " + 
//     "additional information about your restaurant are just a few features from Online Ordering.",

//     "Online Delivery Address Verification – Automatically verify the customer address is within a given radius of " + 
//     "the restaurant before allowing the order.",
//   ],
// }, {
//   Id: "back-office-reports",
//   Title: "BACK OFFICE REPORTS",
//   SolutionIntro: "",
//   FeatureList: [
//     "Daily Summary",
//     " Tax Summary",
//     " Surcharge Summary",
//     " Labor Cost By Job",
//     " Labor Cost By Hour",
//     " On Hand Cas",
//     " Duplicate Credit Card Transaction",
//     " Paid Out Detail",
//     "Paid Out Summary",
//     "Paid In Detail",
//     "Paid In Summary",
//     "Online Order Summary",
//     "Open Batch Report",
//     "Settled Batch Report",
//     "Credit Card History",
//     "Credit Card Audit Trail",
//     "Daily Payment Detail",
//     "Department Sales",
//     "Payment Breakdown",
//     "Employee Sales",
//     "Time Period Sales",
//     "Revenue and Sales Summary",
//     "Profit Center Sales",
//     "Payment Method Sales Summary",
//     "Workstation Payment Summary",
//     "Guest Order Information",
//     "Guest List",
//     "Product Mix",
//     "Product Mix Comparative",
//     "Product Mix Hourly",
//     "Menu Item Sold Tracking (Contest)",
//     "Top 50 Sold",
//     "Menu Item Info",
//     "Employee Info",
//     "Employees Approaching Overtime",
//     "Time Card",
//     "Bi Weekly Time Card",
//     "Tip Income",
//     "Cash Due",
//     "Check Out",
//     "Payroll",
//     "Void Audit Trail",
//     "Comp Audit Trail",
//     "Discount Audit Trail",
//     "Return Audit Trail",
//     "Transfer Audit Trail",
//     "ReOpen Check Audit Trail",
//     "Time Card Audit Trail",
//     "Change Price Audit Trail",
//     "Cash Register Audit Trail",
//     "System Configuration Audit Trail",
//     "Transaction Lookup",
//     "Sale History",
//     "Transaction Log"
//   ],
// }]




export const featuresList = [{
  Id: "front-of-house-features",
  Title: "FRONT OF HOUSE FEATURES",
  SolutionIntro:"",
  FeatureList: [{
      title: "Cashier Banking ",
      content: "Servers can use their own logins for placing orders while cashiers can handle " + 
      "all payments for customers on their way out.  Cash will report on the cashiers reports while still " + 
      "allowing credit cards tips to go to the appropriate server.",
    }, {
      title: "Server Banking",
      content: "Servers are responsible for taking payments on their own orders and all order and " + 
      "payment information stays with the employee who created the order.",
    }, {
      title: "Split Items",
      content: "Easily and quickly split  appetizers or shared plates between 2 or more guests allowing " + 
      "everyone to pay their share, on their own check.",
    }, {
      title: "Split Check",
      content: "Easily and quickly split a check into multiple payments or individual checks for 2 or " + 
      "more guests at the same table.",
    }, {
      title: "Seat Number Management",
      content: "Assigning seat numbers to each customer at a table makes it easier to service " + 
      "a table more easily by multiple staff members.",
    }, {
      title: "Unlimited Item Modifiers",
      content: "Adjust specific items via attached  modifier bank for easy access or else type " + 
      "in special instructions to make sure orders are prepared properly.",
    }, {
      title: "Unlimited Universal Modifiers",
      content: "A complete list of adjustments that can be used on any Check, Tab or Item at " + 
      "any time. i.e. Sauce On Side, Extra plate, To Go, etc.",
    }, {
      title: "Pizza Modifier",
      content: "Left Half / Right Half - The ability to add topping to a specific half of a pizza and communicate " + 
      "that to the kitchen as well as having separate pricing for the adjusted portion size: Small - Medium - Large.",
    }, {
      title: "Quick Keys or Hot Keys for Quick Access",
      content: "Keep specific items, modifiers or instructions always available to your " + 
      "employees regardless of what menu category they are currently viewing.",
    }, {
      title: "Reorder button",
      content: "Order another round for your table without needing to redo modifiers or double check what you’ve entered.",
    }, {
      title: "Remove Button",
      content: "Servers can easily correct mistakes they’ve made without needing to get a manager as long as the " + 
      "items haven’t printed out in the Bar\\Kitchen.",
    }, {
      title: "Send 1st Functions",
      content: "Servers can easily choose when to send items to Kitchen or Bar printers without exiting from " + 
      "the entire order.  Easily get appetizers on their own separate tickets and a round of drinks printing while still " + 
      "entering in the entrees all done on one screen.",
    }, {
      title: "Meal Coursing",
      content: "Course out a table, setting up all five courses on one ticket.  Easily send “Fire Tickets” back " + 
      "to the kitchen whenever the customer is ready for their next course.",
    }, {
      title: "Hold / Fire and Timed Hold",
      content: "You can enter an item but “Hold” it until the customer is ready.  Fire (unhold)  the " + 
      "items when you want it to print, or set a timer and it will unhold and print automatically.",
    }, {
      title: "QR Code Ordering",
      content: "We can provide QR codes for your online ordering website to be placed on coupons or to-go menus " + 
      "to give your customers easy access.  We can also provide QR codes for specific tables.  Now the customers can place " + 
      "an order when they first sit down without even needing to speak with a server.  It prints in the kitchen and notifies " + 
      "employees on FOH that a new order is on the table.",
    }, {
      title: "A920 Order and Pay at the Table",
      content: "With the A920 chip reader not only can you take payments table side but also starting " + 
      "tables creating the order and adding to it can all be done from a handheld chip reader.",
    }, {
      title: "Merge Tables",
      content: "Take two open table or checks and combine them to one.",
    }, {
      title: "Happy Hour/Alternate Pricing",
      content: "Secondary pricing that specific items automatically change to during a " + 
      "predetermined time period.",
    }, {
      title: "Quantity Ordering",
      content: "Order any item in multiple quantities opposed to one at a time.",
    }, {
      title: "Fast Bar",
      content: "Create an order and process the tender for one screen. This is mostly used in a fast pace bar " + 
      "environment but can be used for quick service restaurants.",
    }, {
      title: "BarTab",
      content: "Hold customer information to enable continued addition to an open check without opening a table.",
    }, {
      title: "Chip Reader Bar Tabs",
      content: "Start a bar tab by inserting the card into the chip reader and you can hand it back to " + 
      "the customer without needing it again for payment.",
    }, {
      title: "Bar Printing / Remote Printing",
      content: "Send orders to your bar or any other production location in your restaurant " + 
      "without needing a full pos station at that location.",
    }, {
      title: "Gift Cards",
      content: "Hotsauce Gift Card program allows the user to control their own internal gift card program and " + 
      "tracking of the purchase and amounts. Gift card can be purchased directly from Hotsauce Technologies.",
    }, {
      title: "Guest Loyalty",
      content: "Track customer sales to allow them to accumulate points that they can redeem for goods  " + 
      "and/or services provided by your business.",
    }, {
      title: "Future Orders",
      content: "Preset / schedule orders in the system that will automatically be sent to kitchen st the " + 
      "predetermined time and date.",
    }, {
      title: "Driver Routing",
      content: "Assign orders to drivers, track which drivers are out on delivery and which have checked back in. " + 
      " Get one report per driver at the end of the night that has their sales and tips already sorted.",
    }, {
      title: "Item Recipes Accessibility",
      content: "Make servers aware of what ingredients are in each dish to help with " + 
      "customers’ allergies.  Allows bartenders to check and print drink recipes if they are unfamiliar.",
    }, {
      title: "Gratuity \\ Auto Gratuity",
      content: "Easily add gratuity and adjust a ticket for finalization as well as instituting a " + 
      "threshold for gratuity to be added to a check automatically.",
    }, {
      title: "Tax/Inclusive Tax/Smart Tax",
      content: "The ability to have any item prices as a flat dollar amount ( $1.00, $5.00 - $10.00 ) " + 
      "include the required taxes due. The POS reporting will adjust the total amount from the taxes for financial report.",
    }, {
      title: "House Accounts",
      content: "Allow customers to charge directly to a premade account that can be paid down at any time in the future.",
    }, {
      title: "Cash Discount / Non Cash Fee",
      content: "The ability to add a small percentage to an transaction to cover the costs " + 
      "associated with credit card processing.",
    }, {
      title: "Fingerprint Readers",
      content: "Increase the security of staff access to your pos with the addition of fingerprint " + 
      "identification readers to a system.",
    }],
}, {
  Id: "manager-functions",
  Title: "MANAGER FUNCTIONS",
  SolutionIntro:"",
  FeatureList: [{
    title: "Manager Cards",
    content: "Control access to manager permissions with the use of manager cards. This will ensure that " + 
    "staff can not know or discover manager access codes.",
  },{
    title: "Customizable Dining Room Display",
    content: "Dining room displayed on screen can be created to match in-house setup for " + 
    "easier orientation and tracking of tables in use.",
  },{
    title: "86 Item Management",
    content: "86 Items (mark them out of stock) with a few buttons and prevent employees from ordering " + 
    "items you don’t have.  Employees are made aware during clock-in of what items aren’t available and can easily " + 
    "print out the list at any time to keep in their server pad for referencing during their shift.",
  },{
    title: "Menu Item Countdown",
    content: "Track items counts and make employees aware of limited availability.  Enter a countdown " + 
    "of an item before a busy rush and don’t worry about when you run out.  The system will automatically add the item " + 
    "to the 86 list and employees can’t order it until a manager resets the count.",
  },{
    title: "Real Time Labor Reports",
    content: "Track labor percentages mid-shift to see if it’s time to start cutting people.",
  },{
    title: "Real Time Sales Reports",
    content: "Track sales throughout the day to see how you’re doing.  Sales can be emailed or texted " + 
    "at preset times without needing to contact anyone onsite for the information.",
  },{
    title: "Price Change from Front of the House",
    content: "Easily edit item prices from the FOH software in case you made a mistake " + 
    "setting up an item or just starting to offer an existing item as a special.",
  },{
    title: "All Table Function",
    content: "Managers or owners can easily see what’s going on in the entire restaurant and add or edit " + 
    "orders without needing to go through the table’s server.",
  },{
    title: "Voids",
    content: "Use Void if you made a mistake or were testing an item.  It will zero the cost and remove the item from any " + 
    "inventory reports",
  },{
    title: "Comps",
    content: "Use comp if food is wasted, spilled or made incorrectly.  The item is zeroed out but will still " + 
    "report to inventory.",
  },{
    title: "Discounts",
    content: "Discount items on the fly by percentage or dollar amount.",
  },{
    title: "Transfer Tables\\Guests",
    content: "Easily transfer tables between employees or to a different table.  Transfer individual " + 
    "guests if not everyone moves.",
  },{
    title: "Profit Center Tracking",
    content: "Track where your sales are made within your business.  Simple profit centers may be Delivery, " + 
    "Carryout or Main Dining, but you can get more specific per dining room, or even table.  Track your Patio vs Banquet " + 
    "vs Cocktail tables to see where customers prefer to dine.",
  },{
    title: "End of Day Reports",
    content: "Simplify your closing process by setting up Autobatch and EOD reports.  Your POS will " + 
    "automatically settle all credit card trx and then email you, managers and\\or accountants with a wide variety of " + 
    "reports automatically.",
  },{
    title: "Server Revenue Reports ",
    content: "Track employee’s sales over date ranges or monitor their sales in real time to see who your " + 
    "producers are and those who may need more training.",
  },{
    title: "Online Order Maintenance",
    content: "If online orders are backing up you can easily disable or change your estimated wait times " + 
    "from FOH to better inform your customers.",
  },{
    title: "Customer Database",
    content: "Store customer information based on telephone numbers.  This allows reordering of previous items, " + 
    "saving special requests, view their sales history or search and filter your database to hone in on specific demographics.",
  },{
    title: "House Account Management",
    content: "Extend credit to frequent customers, friendly businesses or schools.  House accounts allow " + 
    "the order to be closed without payment and then invoice the customer later on.  This encourages repeat sales and is " + 
    "crucial for catering\\delivery.",
  },{
    title: "Guest Count Analytics",
    content: "Easily break down sales by number of guest per day, week, hour.",
  },{
    title: "Employee Messaging",
    content: "Managers can communicate with the entire or individual staff members. i.e. Today's Special, " + 
    "We are out of.. , Please come talk to me after your shift.",
  },{
    title: "Item Cost Manager",
    content: "Input the cost of each item.  Track on product mix reports and get a theoretical food cost that " + 
    "factors in sales and comps of an item.",
  }],
}, {
  Id: "back-of-house-functions",
  Title: "BACK OF HOUSE FUNCTIONS",
  SolutionIntro: "",
  FeatureList: [{
    title: "Expo Printing",
    content: "Items can be sent to specific stations within the kitchen without printing on tickets sent to " + 
    "different kitchen stations.  An Expo printer can be set up to receive all items and make sure the Expediter " + 
    "trays up everything correctly.",
  },{
    title: "Multiple Print Zones/ Multiple Kitchen Printers",
    content: "Set items to only print to their specific station in the " + 
    "kitchen without including all items on the order.",
  },{
    title: "Kitchen Ticket Consolidation",
    content: "Easily group together like items to reduce ticket length and make it easier for " + 
    "your kitchen manager to call orders.",
  },{
    title: "Kitchen Display Systems ( KDS )",
    content: "Screens along with a bump bar allow your cooks to manage orders without any " + 
    "tickets involved.  You can also use a combination of Kitchen Display systems and printers if you’d still like " + 
    "tickets for specific stations expo.",
  },{
    title: "Kitchen Ticket Summary",
    content: "Summarize an entire order for expo; or summarize items sent to other kitchen stations " + 
    "without giving unnecessary details.  (Ex=The flattop doesn’t need to know the toppings, sauce or details of " + 
    "the pizzas cooking, but he does need to know that 2 pizzas are going out with the hamburgers he’s making.)",
  },{
    title: "Online Ordering",
    content: "Hotsauce will build and maintain an online ordering website for you.  Orders placed automatically " + 
    "print in the kitchen.  Customizable design for the web layout, upload pictures of your menu items and display " + 
    "additional information about your restaurant are just a few features from Online Ordering.",
  },{
    title: "Online Delivery Address Verification",
    content: "Automatically verify the customer address is within a given radius of " + 
    "the restaurant before allowing the order.",
  }],
}, {
  Id: "back-office-reports",
  Title: "BACK OFFICE REPORTS",
  SolutionIntro: "",
  FeatureList: [{
    title: "Daily Summary",
    content: "",
  },{
    title: "Tax Summary",
    content: "",
  },{
    title: "Surcharge Summary",
    content: "",
  },{
    title: "Labor Cost By Job",
    content: "",
  },{
    title: "Labor Cost By Hour",
    content: "",
  },{
    title: "On Hand Cash",
    content: "",
  },{
    title: "Duplicate Credit Card Transaction",
    content: "",
  },{
    title: "Paid Out Detail",
    content: "",
  },{
    title: "Paid Out Summary",
    content: "",
  },{
    title: "Paid In Detail",
    content: "",
  },{
    title: "Paid In Summary",
    content: "",
  },{
    title: "Online Order Summary",
    content: "",
  },{
    title: "Open Batch Report",
    content: "",
  },{
    title: "Settled Batch Report",
    content: "",
  },{
    title: "Credit Card History",
    content: "",
  },{
    title: "Credit Card Audit Trail",
    content: "",
  },{
    title: "Daily Payment Detail",
    content: "",
  },{
    title: "Department Sales",
    content: "",
  },{
    title: "Payment Breakdown",
    content: "",
  },{
    title: "Employee Sales",
    content: "",
  },{
    title: "Time Period Sales",
    content: "",
  },{
    title: "Revenue and Sales Summary",
    content: "",
  },{
    title: "Profit Center Sales",
    content: "",
  },{
    title: "Payment Method Sales Summary",
    content: "",
  },{
    title: "Workstation Payment Summary",
    content: "",
  },{
    title: "Guest Order Information",
    content: "",
  },{
    title: "Guest List",
    content: "",
  },{
    title: "Product Mix",
    content: "",
  },{
    title: "Product Mix Comparative",
    content: "",
  },{
    title: "Product Mix Hourly",
    content: "",
  },{
    title: "Menu Item Sold Tracking (Contest)",
    content: "",
  },{
    title: "Top 50 Sold",
    content: "",
  },{
    title: "Menu Item Info",
    content: "",
  },{
    title: "Employee Info",
    content: "",
  },{
    title: "Employees Approaching Overtime",
    content: "",
  },{
    title: "Time Card",
    content: "",
  },{
    title: "Bi Weekly Time Card",
    content: "",
  },{
    title: "Tip Income",
    content: "",
  },{
    title: "Cash Due",
    content: "",
  },{
    title: "Check Out",
    content: "",
  },{
    title: "Payroll",
    content: "",
  },{
    title: "Void Audit Trail",
    content: "",
  },{
    title: "Comp Audit Trail",
    content: "",
  },{
    title: "Discount Audit Trail",
    content: "",
  },{
    title: "Return Audit Trail",
    content: "",
  },{
    title: "Transfer Audit Trail",
    content: "",
  },{
    title: "ReOpen Check Audit Trail",
    content: "",
  },{
    title: "Time Card Audit Trail",
    content: "",
  },{
    title: "Change Price Audit Trail",
    content: "",
  },{
    title: "Cash Register Audit Trail",
    content: "",
  },{
    title: "System Configuration Audit Trail",
    content: "",
  },{
    title: "Transaction Lookup",
    content: "",
  },{
    title: "Sale History",
    content: "",
  },{
    title: "Transaction Log",
    content: "",
  }],
}]