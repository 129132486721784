export const solutionsList = [{
  Id: "mission-control",
  Title: "MISSION CONTROL",
  SolutionIntro:
    "HotSauce™ Mission Control provides you with a centralized location for accessing all your REAL TIME reporting, " +
    "labor scheduling and inventory needs, giving you more control over your business." +
    "Users of HotSauce™ Mission Control Solution, such as corporate executives, accountants, district managers or store managers, " +
    "can instantly analyze single store information or perform comparative analysis across multiple locations – " +
    "all without redundant, error-prone manual data entry.",
  FeatureList: null,
}, {
  Id: "online-ordering",
  Title: "ONLINE ORDERING",
  SolutionIntro:
    "HotSauce™ Online Ordering service gives you the ability to further expand your business " +
    "by having your own customizable store website with simple intuitive interface for ordering. " +
    "Features including delivery, curbside pickup and order in advance, " +
    "it is fully integrated with your existing HotSauce POS Terminal to streamline your service in a fast growing online market.",
  FeatureList: null,
}, {
  Id: "giftcards-loyalty",
  Title: "GIFT CARDS / LOYALTY",
  SolutionIntro:
    "HotSauce™ Gift Cards allows your business to presell and track gift cards through the pos system. " + 
    "Cards can be purchased through Hotsauce Technologies directly or from any gift card supplier you wish " + 
    "to use, as long as the cards meet Hotsauce Gift Card specifications.",
  FeatureList: null,
}, {
  Id: "mobile-POS",
  Title: "MOBILE POS",
  SolutionIntro:
    "Powered by HotSauce Mobile POS APP, it is fully integrated on PAX A920 all-in-one payment terminal. " +
    "This solution compliments your existing HotSauce POS services with Order & Pay-at-the-Table option, " +
    "made possible by simple and intuitive user interface.",
  FeatureList: null,
  // }, {
  //   Id: "tableside-solution",
  //   Title: "TABLESIDE SOLUTION",
  //   SolutionIntro:
  //     "With the New Hotsauce Tableside Soluton, servers can input orders directly " +
  //     "from the table enabling waitstaff to send orders directly to the kitchen or bar for a dramatic increase in efficiency. " +
  //     "This solution provides many benefits for both the restaurant and customers by streamlining the ordering process, " +
  //     "improving order accuracy and increasing table turns.",
  // FeatureList: null,
}, {
  Id: "KDS",
  Title: "KITCHEN DISPLAY SYSTEM",
  SolutionIntro:
    "HotSauce KDS is a reliable way to synchronize orders to the kitchen, " +
    "it is an easy-to-install and easy-to-use solution to streamline your food preparation process.",
  FeatureList: null,
// }, {
//   Id: "kiosk",
//   Title: "KIOSK",
//   SolutionIntro:
//     "",
//   FeatureList: null,
}, {
  Id: "software-integrations",
  Title: "SOFTWARE INTEGRATIONS",
  SolutionIntro:
    "At Hotsauce Technologies, we strive to develop and distribute the best restaurant point of sale software " + 
    "in the industry. All point of sale features and functions are created by Hotsauce Technologies. " + 
    "There are certain features and functions that Hotsauce Technologies has chosen to utilize outside software " + 
    "manufactures to ensure we offer the best pos solutipn possible to our end users. We do not directly sell " + 
    "these add on softwares but the Hotsauce Technologies Team will assist you with guding you through their sales process.",
  FeatureList: [{
    title: "Quickbooks",
    content: "",
  },{
    title: "Orca",
    content: "",
  },{
    title: "Chowly",
    content: "",
  }],
}]